import V2Adapter from './v2';

export default class InviteAdapter extends V2Adapter {
  shouldBackgroundReloadRecord() {
    return false;
  }

  pathForType() {
    return 'guest-invites';
  }

  urlForFindRecord(id, modelName, snapshot) {
    let baseUrl = this.buildURL(modelName, id, snapshot);
    let queryParams = new URLSearchParams(snapshot.adapterOptions).toString();
    return `${baseUrl}?${queryParams}`;
  }
}
