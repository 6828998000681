import { service } from '@ember/service';
import UserActivityService from 'ember-user-activity/services/user-activity';
import type ScrollActivityService from 'invite-sign/services/scroll-activity';

export default class UserActivity extends UserActivityService {
  // The underlying dependency requires its own scrollActivity service.
  // Declared here for TypeScript.
  @service declare scrollActivity: ScrollActivityService;

  EVENT_THROTTLE: number = 500; // 500 ms
  IDLE_TIMEOUT: number = 180000; // 3 minutes
}
