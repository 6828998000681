import { objectAssign } from '../utils/lang/objectAssign';
import { releaseApiKey } from '../utils/inputValidation/apiKey';
import { clientFactory } from './client';
import { clientInputValidationDecorator } from './clientInputValidation';
/**
 * Creates an Sdk client, i.e., a base client with status and destroy interface
 */
export function sdkClientFactory(params, isSharedClient) {
    var sdkReadinessManager = params.sdkReadinessManager, syncManager = params.syncManager, storage = params.storage, signalListener = params.signalListener, settings = params.settings, telemetryTracker = params.telemetryTracker, uniqueKeysTracker = params.uniqueKeysTracker;
    return objectAssign(
    // Proto-linkage of the readiness Event Emitter
    Object.create(sdkReadinessManager.sdkStatus), 
    // Client API (getTreatment* & track methods)
    clientInputValidationDecorator(settings, clientFactory(params), sdkReadinessManager.readinessManager), 
    // Sdk destroy
    {
        destroy: function () {
            // record stat before flushing data
            if (!isSharedClient)
                telemetryTracker.sessionLength();
            // Stop background jobs
            syncManager && syncManager.stop();
            var flush = syncManager ? syncManager.flush() : Promise.resolve();
            return flush.then(function () {
                // Cleanup event listeners
                sdkReadinessManager.readinessManager.destroy();
                signalListener && signalListener.stop();
                // Release the API Key if it is the main client
                if (!isSharedClient)
                    releaseApiKey(settings.core.authorizationKey);
                if (uniqueKeysTracker)
                    uniqueKeysTracker.stop();
                // Cleanup storage
                return storage.destroy();
            });
        }
    });
}
