import { camelize } from '@ember/string';

type Objs = {
  [key: string]: unknown;
};

export default function nestedCamelizer(objs: Objs[]): unknown[] {
  return objs.map((language) => {
    const keyValues = Object.keys(language).map((key) => {
      const newKey = camelize(key);
      return { [newKey]: language[key] };
    });
    return <unknown>Object.assign({}, ...keyValues);
  });
}
