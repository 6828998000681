import Service from '@ember/service';

type MobileMessage = {
  action: string;
};

interface Webkit {
  webkit: {
    messageHandlers: {
      envoyMobile: {
        postMessage(message: string): void;
      };
    };
  };
}

export default class MobileMessageService extends Service {
  close(): void {
    this.sendMobileMessage({
      action: 'CLOSE',
    });
  }

  sendMobileMessage(payload: MobileMessage): void {
    const message = JSON.stringify(payload);

    // Send close action for iOS/iPadOS (if applicable)
    (window as Webkit & Window & typeof globalThis).webkit?.messageHandlers?.envoyMobile?.postMessage(message);

    // Send close action for Android (if applicable)
    this.tryPostAndroidMessage(message);
  }

  tryPostAndroidMessage(message: string): void {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (typeof JSInterface !== 'undefined' && typeof JSInterface.postMessage === 'function') {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      JSInterface.postMessage(message);
    }
  }
}
