import ActiveStorage from '@algonauti/ember-active-storage/services/active-storage';
import { service } from '@ember/service';
import type SessionService from 'invite-sign/services/session';

export default class ActiveStorageService extends ActiveStorage {
  @service declare session: SessionService;

  get headers(): string | Record<string, string> {
    if (this.session.sessionData && this.session.isAuthenticated()) {
      const { access_token } = this.session.sessionData;
      return { authorization: `Bearer ${access_token}` };
    }
    return '';
  }
}
