import Controller from '@ember/controller';
import { service } from '@ember/service';
import type SessionService from 'invite-sign/services/session';

export default class InErrorController extends Controller {
  @service declare session: SessionService;

  get isDeactivated(): boolean {
    return this.session.errorData?.message === 'revoked';
  }
}
