import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';

/**
 * @param {Model<UserDocumentTemplate>}              currentDocumentTemplate
 * @param {Function}                                 back
 * @param {Function}                                 skip
 * @param {Function}                                 next
 * @param {String}                                   inviteId
 * @param {String}                                   recurringInviteId
 */
const DESCRIPTION_VIEW = 'description';
const REVIEW_VIEW = 'review';
export default class DocumentHandler extends Component {
  @service store;

  @tracked currentView;
  @tracked currentAttachment;
  @tracked uploading = false;

  descriptionView = DESCRIPTION_VIEW;
  reviewView = REVIEW_VIEW;
  validFileTypes = /image\/(gif|jpe?g|png)/;

  isMobile = false;

  constructor() {
    super(...arguments);

    this.isMobile = navigator?.userAgent?.match(/(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i);
  }

  get isDescriptionView() {
    return this.currentView === this.descriptionView;
  }

  get isAttachmentView() {
    return this.currentView === this.currentAttachment?.id;
  }

  get isReviewView() {
    return this.currentView === this.reviewView;
  }

  get showSkipAttachment() {
    return !this.currentAttachment?.required && this.isAttachmentView;
  }

  get attachmentIndex() {
    return this.attachments.indexOf(this.currentAttachment);
  }

  get attachments() {
    return this.args.currentDocumentTemplate.userDocumentTemplateAttachments.sortBy('position');
  }

  get nextAttachment() {
    return this.attachments.objectAt(this.attachmentIndex + 1);
  }

  get previousAttachment() {
    return this.attachments.objectAt(this.attachmentIndex - 1);
  }

  get attachmentPrefix() {
    const id = this.args.currentDocumentTemplate.id;

    if (this.args.recurringInviteId) {
      return `user-documents/${id}/recurring-invites/${this.args.recurringInviteId}`;
    }
    if (this.args.inviteId) {
      return `user-documents/${id}/invites/${this.args.inviteId}`;
    }
    if (this.args.entryId) {
      return `user-documents/${id}/entries/${this.args.entryId}`;
    }

    return `user-documents/${id}/unknown/${Date.now()}`;
  }

  get disableNext() {
    const { visitorDocument } = this.args.currentDocumentTemplate;
    if (this.uploading) {
      return true;
    }
    return (
      (this.isAttachmentView && !this.currentAttachment?.userDocumentAttachment?.isValid) ||
      (this.isReviewView && isPresent(visitorDocument.invalidInputFields))
    );
  }

  @action
  onProgress(progress) {
    if (progress === 100) {
      this.uploading = false;
    } else {
      this.uploading = true;
    }
  }

  @action
  onError(error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  @action
  onUploadComplete(fileId) {
    this.uploading = false;
    this.currentAttachment.userDocumentAttachment.file = fileId;
  }

  @action
  onReset() {
    this.currentAttachment.userDocumentAttachment.file = null;
    this.currentAttachment.userDocumentAttachment.fileUrl = null;
  }

  @action
  onFilePreview(src) {
    this.currentAttachment.userDocumentAttachment.fileUrl = src;
  }

  @action
  handleBack() {
    if (this.currentView === this.descriptionView) {
      this.args.back();
    } else if (this.currentView === this.reviewView) {
      this.currentView = this.currentAttachment.id;
    } else if (this.currentView === this.currentAttachment.id && isPresent(this.previousAttachment)) {
      this.currentView = this.previousAttachment.id;
      this.currentAttachment = this.previousAttachment;
      this._createUserDocument();
    } else {
      this.currentView = this.descriptionView;
    }
  }

  @action
  handleNext() {
    if (this.currentView === this.descriptionView) {
      this.currentView = this.currentAttachment.id;
    } else if (this.currentView === this.reviewView) {
      this.args.next();
    } else if (this.currentView === this.currentAttachment.id && isPresent(this.nextAttachment)) {
      this.currentView = this.nextAttachment.id;
      this.currentAttachment = this.nextAttachment;
      this._createUserDocument();
    } else {
      if (this.args.currentDocumentTemplate.hasAnyFilesAssigned()) {
        this.currentView = this.reviewView;
      } else {
        this.args.skip();
      }
    }
  }

  @action
  handleSkip() {
    this.onReset();
    this.handleNext();
  }

  @action
  _resetCurrentView() {
    this.currentView = this.descriptionView;
    this.currentAttachment = this.attachments.firstObject;
    this._createUserDocument();
  }

  _createUserDocument() {
    if (!this.currentAttachment.userDocumentAttachment) {
      this.store.createRecord('user-document-attachment', {
        userDocumentTemplateAttachment: this.currentAttachment,
      });
    }
  }
}
