import JSONAPISerializer from '@ember-data/serializer/json-api';

export default class VisitorDocumentSerializer extends JSONAPISerializer {
  attrs = {
    entryId: {
      serialize: false,
    },
    inviteId: {
      serialize: false,
    },
    locationId: {
      serialize: false,
    },
    recurringInviteId: {
      serialize: false,
    },
  };

  serialize(snapshot, _options) {
    let json = super.serialize(...arguments);
    if (!json.data['relationships']) {
      json.data['relationships'] = {};
    }
    let { attributes, relationships } = json.data;
    attributes['attachments'] =
      snapshot.hasMany('userDocumentAttachments')?.map((attachment) => {
        return {
          file: attachment.attr('file'),
          'user-document-template-attachment': attachment.belongsTo('userDocumentTemplateAttachment').id,
        };
      }) ?? [];
    if (snapshot.record.recurringInviteId) {
      relationships['recurring-invites'] = {
        data: [{ id: snapshot.record.recurringInviteId, type: 'recurring-invites' }],
      };
    } else if (snapshot.record.inviteId) {
      relationships['invites'] = { data: [{ id: snapshot.record.inviteId, type: 'invites' }] };
    }

    if (snapshot.record.entryId) {
      relationships['entries'] = { data: [{ id: snapshot.record.entryId, type: 'entries' }] };
    }

    relationships['locations'] = { data: [{ id: snapshot.record.locationId, type: 'locations' }] };

    relationships['user-document-template'] = {
      data: {
        type: 'user-document-templates',
        id: snapshot.belongsTo('userDocumentTemplate')?.id,
      },
    };

    return json;
  }
}
