import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class SelectedFlowManagerService extends Service {
  @service store;

  flowSelectedTask = task({ drop: true }, async (selectedFlow, entry) => {
    let signInFieldPageId = selectedFlow.belongsTo('signInFieldPage').id();
    let signInFieldPage = this.store.peekRecord('sign-in-field-page', signInFieldPageId);
    if (!signInFieldPage) {
      let includes = [
        'sign-in-field-actions.sign-in-field',
        'actionable-sign-in-field-actions.actionable-sign-in-field',
        'actionable-sign-in-fields',
      ];
      signInFieldPage = await this.store.findRecord('sign-in-field-page', signInFieldPageId, {
        include: includes.map((include) => `sign-in-fields.${include}`).join(','),
      });
    }
    // Associate the sign-in-fields to the entry
    entry.signInFields = await signInFieldPage.signInFields;

    await entry.save();
  });
}
