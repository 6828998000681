import Model, { attr, belongsTo } from '@ember-data/model';

import type LocationModel from './location';

export default class LocationRole extends Model {
  @belongsTo('location', { async: false })
  declare location: LocationModel;

  @attr('string')
  declare roleName: string;

  @attr('date')
  declare confirmedAt: Date;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'location-role': LocationRole;
  }
}
