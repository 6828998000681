import JSONAPIAdapter from '@ember-data/adapter/json-api';
import config from 'invite-sign/config/environment';
import { v1 as uuidv1 } from 'uuid';

export default class NdaSignatureAdapter extends JSONAPIAdapter {
  namespace = 'api/v2';
  host = config.apiHost;

  buildURL(type, id, snapshot) {
    let url = super.buildURL(...arguments);
    let inviteId = snapshot.belongsTo('agreement').belongsTo('invite').id;
    return url + `/${inviteId}/nda`;
  }

  pathForType() {
    return 'guest-invites';
  }

  handleResponse(status, headers, payload = {}) {
    // We only ever save a single signature, and we get back just a success response
    // so we are gonna make a dummy id here
    payload.data = { id: uuidv1(), type: 'nda-signature' };
    return super.handleResponse(...arguments);
  }
}
