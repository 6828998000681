import Component from '@glimmer/component';
import { action } from '@ember/object';
import SignaturePad from 'signature_pad';
import { inject as service } from '@ember/service';

export default class SignaturePadV2Component extends Component {
  @service router;

  signaturePad = null;

  @action
  initSignaturePad(canvas) {
    const pad = new SignaturePad(canvas);

    pad.addEventListener('endStroke', () => {
      this.signatureChanged();
    });

    this.recomputeSize(pad, canvas);
    this.signaturePad = pad;
  }

  @action
  signatureChanged() {
    let data = '';

    if (!this.signaturePad.isEmpty()) {
      data = this.signaturePad.toDataURL();
    }

    this.args.onChange(data);
  }

  @action
  clear() {
    this.signaturePad.clear();
    this.args.onClear();
  }

  @action
  renderSignature(canvas, [signature]) {
    this.signaturePad.clear();

    if (signature) {
      this.signaturePad.fromDataURL(signature);
    }

    if (this.args.disabled) {
      this.signaturePad.off();
    } else {
      this.signaturePad.on();
    }
  }

  @action
  recomputeSize(pad, canvas) {
    let ratio = Math.max(window.devicePixelRatio || 1, 1);

    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);

    pad.clear();
  }
}
