import JSONAPISerializer from '@ember-data/serializer/json-api';

export default class VisitorSignatureNdaSerializer extends JSONAPISerializer {
  payloadKeyFromModelName(modelName) {
    let payloadKey = modelName;

    if (payloadKey === 'visitor-nda-signature') {
      payloadKey = 'ndas';
    }

    if (payloadKey === 'agreement') {
      payloadKey = 'agreements';
    }

    return payloadKey;
  }
}
