import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type SessionService from 'invite-sign/services/session';

export default class InDisconnectSharedRoute extends Route {
  @service declare session: SessionService;
  @service declare router: RouterService;

  // eslint-disable-next-line @typescript-eslint/require-await
  async model({ public_token: publicToken }: { public_token: string }): Promise<void> {
    if (this.session.sharedDeviceToken === publicToken) {
      this.session.clearDeviceSettings();
    }

    void this.router.replaceWith('in.authenticate', publicToken);
  }
}
