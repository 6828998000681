import JSONAPISerializer from '@ember-data/serializer/json-api';
import { get } from '@ember/object';

export default class EntrySerializer extends JSONAPISerializer {
  attrs = {
    finalizedAt: {
      serialize: false,
    },
    signedInAt: {
      serialize: false,
    },
    createdAt: {
      serialize: false,
    },
  };

  normalize(typeClass, hash) {
    let { attributes, relationships } = hash;

    delete attributes['photo'];

    /*
    Note: we need to remove the sign-in-fields relationship from the hash,
    because it's an empty array and ember-data will sync the store with the
    response coming from the API, otherwise setting sign-in-fields on entry and
    saving it, the assigned sign-in-fields will be removed from the entry
    model relationship.
    */
    if (relationships) {
      delete relationships['sign-in-fields'];
    }

    return super.normalize(...arguments);
  }

  serialize(snapshot, _options) {
    let json = super.serialize(...arguments);
    let { attributes, relationships } = json.data;

    attributes['user-data'] = this._generateUserData(snapshot.record, snapshot.record.signInFields);
    attributes['photo-base64'] = attributes['photo'];

    if (attributes) {
      delete attributes['photo'];
    }
    if (relationships) {
      delete relationships['sign-in-fields'];
      delete relationships['agreements'];
    }

    const inviteId = get(snapshot.record, 'invite.id');
    if (inviteId) {
      relationships['invite'] = {
        data: {
          id: get(snapshot.record, 'invite.id'),
          type: 'invites',
        },
      };
    }

    return json;
  }

  _generateUserData(record, signInFields) {
    let userData = {};
    signInFields.forEach(({ identifier, label, value }) => {
      switch (identifier) {
        case 'name':
          userData[label] = record.fullName;
          break;
        default:
          userData[label] = value;
      }
    });

    return userData;
  }
}
