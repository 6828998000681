import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Webcam from 'webcamjs';

/**
 * @param {Function}           didSnap
 * @param {Function}           didError
 */
export default class WebCam extends Component {
  @tracked camera = null;

  cameraId = 'cam-' + Math.random().toString(36).substr(2, 10);
  swfLocation = '/assets/webcam.swf';

  constructor() {
    super(...arguments);
    this.camera = {
      snap: this.snap.bind(this),
    };
  }

  @action
  onInsert(element) {
    // eslint-disable-next-line ember/use-ember-get-and-set
    Webcam.set('enable_flash', true);
    // eslint-disable-next-line ember/use-ember-get-and-set
    Webcam.set('swfURL', this.swfLocation);
    Webcam.on('error', (error) => {
      if (!this.isDestroying && !this.isDestroyed && this.args.didError) {
        this.args.didError(error);
      }
    });
    Webcam.attach('#' + this.cameraId);
    this.fixPreviewPosition(element);
  }

  @action
  handleWillDestroy() {
    Webcam.reset();
    Webcam.off('error');
  }

  snap() {
    Webcam.snap((dataUri) => {
      if (!this.isDestroying && !this.isDestroyed && this.args.didSnap) {
        this.args.didSnap(dataUri);
      }
    });
  }

  /**
   * Adjusts the webcam preview position to be centered in the component if its scaled size do not match
   * the component wrapper div size
   *
   * @param {HTMLDivElement} wrapper - component wrapper div
   */
  fixPreviewPosition(wrapper) {
    const wrapperHeight = wrapper.clientHeight;
    const wrapperWidth = wrapper.clientWidth;

    const aspectRatio = Webcam.params.height / Webcam.params.width;

    const heightOffset = (Webcam.params.height - wrapperHeight) * 2 * aspectRatio + 28;
    const widthOffset = (Webcam.params.width - wrapperWidth) * 2 * aspectRatio + 28;

    Webcam.video.style.transformOrigin = `${widthOffset}px ${heightOffset}px`;
  }
}
