import V2Adapter from './v2';

export default class UserAdapter extends V2Adapter {
  // Customized to allow for `store.queryRecord('user', { id: 'me' })` to be
  // used when fetching the current user.
  //
  // Using `store.findRecord('user', 'me')` is not good because the resource returned
  // from the server has a different `id`, which ember-data does not like.
  urlForQueryRecord(query, modelName) {
    const url = super.urlForQueryRecord(...arguments);

    if (modelName === 'user' && query.id === 'me') {
      delete query.id;
      return `${url}/me`;
    } else {
      return url;
    }
  }
}
