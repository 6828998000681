import Model, { attr } from '@ember-data/model';

export default class Location extends Model {
  @attr('string')
  declare address: string;

  @attr('string')
  declare addressLineOne: string;

  @attr('string')
  declare addressLineTwo: string;

  @attr('string')
  declare city: string;

  @attr('string')
  declare state: string;

  @attr('string')
  declare zip: string;

  @attr('string')
  declare country: string;

  @attr('string')
  declare logoUrl: string;

  @attr('string')
  declare slideUrl: string;

  @attr('boolean')
  declare hideLogo: boolean;

  @attr('string')
  declare name: string;

  @attr('number')
  declare companyId: number;

  @attr('string')
  declare companyName: string;

  @attr('string')
  declare buttonColor: string;

  @attr('string')
  declare buttonTextColor: string;

  @attr('boolean')
  declare touchlessSigninEnabled: boolean;

  @attr('string')
  declare locale: string;

  @attr('string')
  declare timezone: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    location: Location;
  }
}
