import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import Plyr from 'plyr';

export default class VideoPlayerComponent extends Component {
  @action
  setup(element) {
    this.player = new Plyr(element);
    this.setEventListeners();
  }

  switchTask = task({ drop: true }, async () => {
    await timeout(10);
  });

  get isYouTube() {
    return !!this.args.url?.match(/youtube/);
  }

  get isVimeo() {
    return !!this.args.url?.match(/vimeo/);
  }

  setEventListeners() {
    this.player.on('play', (e) => {
      if (typeof this.args.onPlay === 'function') {
        this.args.onPlay(e);
      }
    });
  }

  willDestroy() {
    this.player.destroy();
    super.willDestroy(...arguments);
  }
}
