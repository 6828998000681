import V3Adapter from './v3';
import { inject as service } from '@ember/service';

export default class VisitorNdaSignatureAdapter extends V3Adapter {
  @service store;

  /**
   * There be dragons here
   *
   * We only ever have one entry WITH AN ID (the "previous" entry)
   * so we're querying the store directly
   * instead of hopping back up the relationship chain like
   * snapshot.belongsTo('agreement').belongsTo('agreementPage')
   *    .belongsTo('flow').belongsTo('entry').id
   */
  buildURL() {
    const url = super.buildURL(...arguments);
    const entries = this.store.peekAll('entry');
    const previousEntry = entries.find((e) => e.id);

    return url + `/${previousEntry.id}/ndas`;
  }

  pathForType() {
    return 'entries';
  }

  handleResponse(status, headers, payload = {}) {
    payload.data.type = 'visitor-nda-signature';
    return super.handleResponse(...arguments);
  }
}
