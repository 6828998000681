import Component from '@glimmer/component';
import { action } from '@ember/object';
import { formatter } from 'invite-sign/helpers/date-format';
import { inject as service } from '@ember/service';

/**
 * @param {Model<UserDocumentTemplate>}              currentDocumentTemplate
 */
export default class DocumentReviewerHandler extends Component {
  @service localization;

  get attachmentsWithFiles() {
    return this.args.currentDocumentTemplate.userDocumentTemplateAttachments
      .filter((attachment) => attachment.userDocumentAttachment?.file)
      .sortBy('position');
  }

  get inputFields() {
    return this.args.currentDocumentTemplate.visitorDocument.inputFields;
  }

  get maxDate() {
    return new Date();
  }

  @action
  onDateSelected(inputField, value) {
    inputField.value = value;
  }

  @action
  formatDate(value) {
    const formatOptions = { locale: this.localization.dateFnsLocale };
    return formatter([value, 'MMMM d, yyyy', formatOptions]);
  }
}
