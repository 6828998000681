import Component from '@glimmer/component';

export default class YouTubeComponent extends Component {
  get videoUrl() {
    const url = new URL(this.args.url);
    this.setDefaultSearchParams(url.searchParams);
    return url.toString();
  }

  setDefaultSearchParams(params) {
    /* eslint-disable ember/use-ember-get-and-set */
    params.set('iv_load_policy', '3');
    params.set('modestbranding', '1');
    params.set('playsinline', '1');
    params.set('showinfo', '0');
    params.set('rel', '0');
    params.set('enablejsapi', '1');
  }
}
