import Model, { attr, belongsTo } from '@ember-data/model';

export default class GdprConfiguration extends Model {
  @belongsTo('location') location;

  @attr('boolean') enabled;
  @attr('string') optOutVisitorProtocol;
  @attr('boolean') optOutVisitorProtocolEnabled;

  @attr('string') policyDisclosure;
  @attr('boolean') policyDisclosureEnabled;
  @attr('boolean') visitorConsentEnabled;
}
