import { evaluateFeature, evaluateFeatures } from '../evaluator';
import { thenable } from '../utils/promise/thenable';
import { getMatching, getBucketing } from '../utils/key';
import { validateSplitExistance } from '../utils/inputValidation/splitExistance';
import { validateTrafficTypeExistance } from '../utils/inputValidation/trafficTypeExistance';
import { SDK_NOT_READY } from '../utils/labels';
import { CONTROL, TREATMENT, TREATMENTS, TREATMENT_WITH_CONFIG, TREATMENTS_WITH_CONFIG, TRACK } from '../utils/constants';
import { IMPRESSION, IMPRESSION_QUEUEING } from '../logger/constants';
import { isStorageSync } from '../trackers/impressionObserver/utils';
var treatmentNotReady = { treatment: CONTROL, label: SDK_NOT_READY };
function treatmentsNotReady(splitNames) {
    var evaluations = {};
    splitNames.forEach(function (splitName) {
        evaluations[splitName] = treatmentNotReady;
    });
    return evaluations;
}
/**
 * Creator of base client with getTreatments and track methods.
 */
export function clientFactory(params) {
    var readinessManager = params.sdkReadinessManager.readinessManager, storage = params.storage, settings = params.settings, impressionsTracker = params.impressionsTracker, eventTracker = params.eventTracker, telemetryTracker = params.telemetryTracker;
    var log = settings.log, mode = settings.mode;
    function getTreatment(key, splitName, attributes, withConfig) {
        if (withConfig === void 0) { withConfig = false; }
        var stopTelemetryTracker = telemetryTracker.trackEval(withConfig ? TREATMENT_WITH_CONFIG : TREATMENT);
        var wrapUp = function (evaluationResult) {
            var queue = [];
            var treatment = processEvaluation(evaluationResult, splitName, key, attributes, withConfig, "getTreatment" + (withConfig ? 'withConfig' : ''), queue);
            impressionsTracker.track(queue, attributes);
            stopTelemetryTracker(queue[0] && queue[0].label);
            return treatment;
        };
        var evaluation = readinessManager.isReady() || readinessManager.isReadyFromCache() ?
            evaluateFeature(log, key, splitName, attributes, storage) :
            isStorageSync(settings) ? // If the SDK is not ready, treatment may be incorrect due to having splits but not segments data, or storage is not connected
                treatmentNotReady :
                Promise.resolve(treatmentNotReady); // Promisify if async
        return thenable(evaluation) ? evaluation.then(function (res) { return wrapUp(res); }) : wrapUp(evaluation);
    }
    function getTreatmentWithConfig(key, splitName, attributes) {
        return getTreatment(key, splitName, attributes, true);
    }
    function getTreatments(key, splitNames, attributes, withConfig) {
        if (withConfig === void 0) { withConfig = false; }
        var stopTelemetryTracker = telemetryTracker.trackEval(withConfig ? TREATMENTS_WITH_CONFIG : TREATMENTS);
        var wrapUp = function (evaluationResults) {
            var queue = [];
            var treatments = {};
            Object.keys(evaluationResults).forEach(function (splitName) {
                treatments[splitName] = processEvaluation(evaluationResults[splitName], splitName, key, attributes, withConfig, "getTreatments" + (withConfig ? 'withConfig' : ''), queue);
            });
            impressionsTracker.track(queue, attributes);
            stopTelemetryTracker(queue[0] && queue[0].label);
            return treatments;
        };
        var evaluations = readinessManager.isReady() || readinessManager.isReadyFromCache() ?
            evaluateFeatures(log, key, splitNames, attributes, storage) :
            isStorageSync(settings) ? // If the SDK is not ready, treatment may be incorrect due to having splits but not segments data, or storage is not connected
                treatmentsNotReady(splitNames) :
                Promise.resolve(treatmentsNotReady(splitNames)); // Promisify if async
        return thenable(evaluations) ? evaluations.then(function (res) { return wrapUp(res); }) : wrapUp(evaluations);
    }
    function getTreatmentsWithConfig(key, splitNames, attributes) {
        return getTreatments(key, splitNames, attributes, true);
    }
    // Internal function
    function processEvaluation(evaluation, splitName, key, attributes, withConfig, invokingMethodName, queue) {
        var matchingKey = getMatching(key);
        var bucketingKey = getBucketing(key);
        var treatment = evaluation.treatment, label = evaluation.label, changeNumber = evaluation.changeNumber, _a = evaluation.config, config = _a === void 0 ? null : _a;
        log.info(IMPRESSION, [splitName, matchingKey, treatment, label]);
        if (validateSplitExistance(log, readinessManager, splitName, label, invokingMethodName)) {
            log.info(IMPRESSION_QUEUEING);
            queue.push({
                feature: splitName,
                keyName: matchingKey,
                treatment: treatment,
                time: Date.now(),
                bucketingKey: bucketingKey,
                label: label,
                changeNumber: changeNumber
            });
        }
        if (withConfig) {
            return {
                treatment: treatment,
                config: config
            };
        }
        return treatment;
    }
    function track(key, trafficTypeName, eventTypeId, value, properties, size) {
        if (size === void 0) { size = 1024; }
        var stopTelemetryTracker = telemetryTracker.trackEval(TRACK);
        var matchingKey = getMatching(key);
        var timestamp = Date.now();
        var eventData = {
            eventTypeId: eventTypeId,
            trafficTypeName: trafficTypeName,
            value: value,
            timestamp: timestamp,
            key: matchingKey,
            properties: properties
        };
        // This may be async but we only warn, we don't actually care if it is valid or not in terms of queueing the event.
        validateTrafficTypeExistance(log, readinessManager, storage.splits, mode, trafficTypeName, 'track');
        var result = eventTracker.track(eventData, size);
        if (thenable(result)) {
            return result.then(function (result) {
                stopTelemetryTracker();
                return result;
            });
        }
        else {
            stopTelemetryTracker();
            return result;
        }
    }
    return {
        getTreatment: getTreatment,
        getTreatmentWithConfig: getTreatmentWithConfig,
        getTreatments: getTreatments,
        getTreatmentsWithConfig: getTreatmentsWithConfig,
        track: track,
        isClientSide: false
    };
}
