import Component from '@glimmer/component';
import { action, set } from '@ember/object';

export default class EmailFieldComponent extends Component {
  @action
  onChange(e) {
    set(this.args.mappedField, 'value', e.target.value);
    set(this.args.entry, 'email', e.target.value);
    this.args.mappedField.validate();
  }
}
