import Model, { belongsTo, attr } from '@ember-data/model';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed, get } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { equal } from '@ember/object/computed';

export default class SignInFieldAction extends Model {
  @belongsTo('signInField') signInField;
  @belongsTo('signInField', { inverse: 'actionableSignInFieldActions' }) actionableSignInField;
  @attr('string', { defaultValue: 'show' }) action;
  @attr('string') actionValue;
  @attr('string') signInFieldValue;

  @equal('action', 'show') isConditional;
  @equal('action', 'deny_entry') isRule; // v1: all rules are setup to deny_entry on the backend

  /**
   * @return {DropdownOption}
   */
  @computed('signInField.items.[]', 'signInFieldValue')
  get dropdownOption() {
    let options = get(this.signInField, 'items');

    // NOTE: purposely using a `==` type coercion check here
    return options.find((opt) => opt.id == this.signInFieldValue);
  }
}
