import StorageObject from 'ember-local-storage/local/object';

export default class ProtectAuthDataStorage extends StorageObject {
  initialState() {
    return {
      accessToken: null,
      refreshToken: null,
      expires: null,
    };
  }
}
