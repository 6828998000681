import Application from '@ember/application';
import loadInitializers from 'ember-load-initializers';
import config from 'invite-sign/config/environment';
import LogRocket from 'logrocket';

import Resolver from './resolver';

if (config.logRocketAppId) {
  LogRocket.init(config.logRocketAppId, {
    dom: {
      inputSanitizer: true,
    },
    network: {
      responseSanitizer: (response) => {
        delete response.body;
        return response;
      },
      requestSanitizer: (request) => {
        delete request.body;
        return request;
      },
    },
  });
}

class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);

export default App;
