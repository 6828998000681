import JSONAPISerializer from '@ember-data/serializer/json-api';

// eslint-disable-next-line ember/no-classic-classes
export default JSONAPISerializer.extend({
  modelNameFromPayloadKey(payloadType) {
    if (payloadType === 'guest-invites') {
      return 'invite';
    }
    return this._super(...arguments);
  },

  serialize(snapshot) {
    const json = this._super(...arguments);

    if (snapshot.modelName === 'invite') {
      const snapshots = snapshot.hasMany('signInFields');

      if (!snapshots?.length) {
        return json;
      }

      const fields = snapshots.map((field) => field.attributes());
      json.data.attributes.fields = fields;
    }

    return json;
  },
});
