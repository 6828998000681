import Component from '@glimmer/component';
import fade from 'ember-animated/transitions/fade';
import { inject as service } from '@ember/service';
import { action, get, set } from '@ember/object';
import { underscore } from '@ember/string';
import { DISCARDED_RESPONSE_STRINGS } from 'invite-sign/utils/enums';
import { not } from 'macro-decorators';
import { isEmpty } from '@ember/utils';

/**
 * @param {Class<SignInField>}            mappedField     A SignInField model
 * @param {Class<Invite>}                 invite          An invite model
 * @param {Class<Entry>}                  entry           An entry model
 */
export default class MappedField extends Component {
  transiton = fade;
  @service localization;

  @not('isValid') isInvalid;

  get isValid() {
    return isEmpty(this.args.mappedField?.errors);
  }

  get error() {
    return underscore(get(this.args.mappedField, 'errors.messages')[0]?.toString());
  }

  get dropdownSelectedValue() {
    const { value } = this.args.mappedField;

    if (value === DISCARDED_RESPONSE_STRINGS.PLACEHOLDER) {
      return '';
    }

    return value;
  }

  get inputFieldName() {
    return this.args.mappedField.identifier ?? this.args.mappedField.id;
  }

  get visitorRecord() {
    return this.args.invite ?? this.args.entry;
  }

  @action
  validate() {
    this.args.mappedField.validate();
  }

  @action
  handleChange(selection) {
    const { signInFieldActions } = this.args.mappedField;

    if (signInFieldActions.length) {
      const rest = signInFieldActions.filter(
        ({ dropdownOption, isConditional }) => isConditional && dropdownOption.value !== selection
      );

      rest.forEach((action) => {
        set(action, 'actionableSignInField.value', '');
      });
    }
  }
}
