import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { bool } from '@ember/object/computed';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import { memberAction } from 'ember-api-actions';
import { camelize } from '@ember/string';
import { format, utcToZonedTime } from 'date-fns-tz';

export default class Invite extends Model {
  @service localization;
  @service store;

  @belongsTo('location', { async: false }) location;
  @hasMany('agreement') agreements;
  @hasMany('sign-in-field') signInFields;
  @hasMany('user-document-template') userDocumentTemplates;

  @attr('date') expectedArrivalTime;
  @attr('string') approvalStatus;
  @attr('boolean') employeeScreeningFlow;
  @attr('string') purposeOfVisitName;
  @attr('string') headerText;
  @attr('string') denyMessage;
  @attr('boolean', { defaultValue: false }) photoEnabled;
  @attr() thumbnails;
  @attr() photo;
  @attr('string') qrCodeBase64;
  @attr({ defaultValue: () => ({ 'place-id': '' }) }) locality;
  @attr('string') entryId;
  @attr('string') inviteId;
  @attr('string') recurringInviteId;

  @bool('qrCodeBase64') hasQrCode;

  /**
   * @return {Timestamp}
   */
  get formattedTime() {
    const expectedArrivalTime = utcToZonedTime(this.expectedArrivalTime, this.location?.timezone);
    const token = this.employeeScreeningFlow ? 'eeee, PPP' : 'PPPp';

    return format(expectedArrivalTime, token, {
      locale: this.localization.dateFnsLocale,
      timeZone: this.location?.timezone,
    });
  }

  /**
   * @return {Timestamp}
   */
  get stringifiedTime() {
    const expectedArrivalTime = utcToZonedTime(this.expectedArrivalTime, this.location?.timezone);
    return format(expectedArrivalTime, 'PPPPp', {
      locale: this.localization.dateFnsLocale,
      timeZone: this.location?.timezone,
    });
  }

  /**
   * @return {String}
   */
  @computed('signInFields.@each.type')
  get host() {
    let field = this.signInFields.find((field) => field.type === 'Host') || {};
    return field.value;
  }

  /**
   * @return {String}
   */
  @computed('signInFields.@each.type')
  get fullName() {
    let field = this.signInFields.find((field) => field.type === 'Name') || {};
    return field.value;
  }

  /**
   * @return {String}
   */
  @computed('signInFields.@each.type')
  get email() {
    let field = this.signInFields.find((field) => field.type === 'Email') || {};
    return field.value;
  }

  /**
   * @return {String}
   */
  @computed('signInFields.@each.{value,isPurposeOfVisit}', 'purposeOfVisitName')
  get purposeOfVisit() {
    if (this.purposeOfVisitName) {
      return this.purposeOfVisitName;
    }
    return (this.signInFields.find((field) => field.isPurposeOfVisit) || {})['value'];
  }

  isValid() {
    // We don't need to validate the host field or hidden conditional fields
    return this.signInFields
      .map((field) => field.isHost || !field.isVisible || field.validate())
      .every((value) => value);
  }

  toJSON() {
    const {
      data: { attributes },
    } = this.serialize();

    return Object.entries(attributes).reduce((carry, [key, value]) => {
      carry[camelize(key)] = value;

      return carry;
    }, {});
  }
}

Invite.prototype.status = memberAction({
  path: 'invite-status',
  type: 'get',
  after(response) {
    let attributes = response.data.attributes;

    return Object.keys(attributes).reduce((total, key) => {
      total[camelize(key)] = attributes[key];
      return total;
    }, {});
  },
});

Invite.prototype.unfinishedAgreements = memberAction({
  path: 'unfinished-agreements',
  type: 'get',
  after(response) {
    return this.store.push({
      data: response.agreements.map((agreement) => ({
        id: agreement.id,
        type: 'agreement',
      })),
    });
  },
});

Invite.prototype.finalize = memberAction({
  type: 'POST',
  path: 'finalize',
  after(response) {
    return this.store.pushPayload(response);
  },
});
