import Model, { attr, belongsTo } from '@ember-data/model';
import { isPresent } from '@ember/utils';

export default class UserDocumentAttachment extends Model {
  @belongsTo('user-document-template-attachment') userDocumentTemplateAttachment;
  @belongsTo('visitor-document') visitorDocument;

  @attr('string') fileUrl;
  @attr('string') file;

  get isValid() {
    if (this.isNew || this.hasDirtyAttributes) {
      return isPresent(this.file);
    }
    return isPresent(this.fileUrl);
  }
}
