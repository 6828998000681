import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { set } from '@ember/object';
import { action } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import Webcam from 'webcamjs';

export default class PhotoTakerComponent extends Component {
  @tracked photoSelected = null;

  constructor() {
    super(...arguments);
    /* eslint-disable ember/use-ember-get-and-set */
    Webcam.set({
      // maintain aspect ratio of destination preview
      width: 344 / 0.75,
      height: 344,

      // device capture size
      dest_width: 640,
      dest_height: 480,

      // final cropped size
      crop_width: 640,
      crop_height: 480,

      // format and quality
      image_format: 'png',
      flip_horiz: true,
      jpeg_quality: 50,
    });
    /* eslint-enable ember/use-ember-get-and-set */
    if (navigator.userAgent.match(/(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i)) {
      set(this, 'isMobile', true);
    }
  }

  /**
   * This is a lifecycle hook that gets called when the component is inserted into the DOM. This logic can't be in the constructor, because some of the elements are not yet available in the DOM. This is invoked in the template through the `did-insert` modifier.
   */
  @action
  setupComponent() {
    // if a default picture is provided, show it
    if (this.args.defaultImageUri) {
      this._showPicture(this.args.defaultImageUri);
    }
  }

  _showPicture(data) {
    let imageTag = document.getElementsByClassName('image-preview')[0];

    imageTag.onload = () => {
      this._compressPicture(data, imageTag);
    };

    imageTag.setAttribute('src', data);
    imageTag.classList.remove('hidden');
    this.photoSelected = true;
  }

  _compressPicture(data, img) {
    try {
      const canvas = document.createElement('canvas');
      const { width, height } = img;

      // Double the size of the preview element
      const newWidth = width * 2;
      const newHeight = height * 2;

      canvas.width = newWidth;
      canvas.height = newHeight;

      const ctx = canvas.getContext('2d');

      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      const compressedBase64 = canvas.toDataURL('image/png', 0.5);

      this.args.onImageSelected(compressedBase64);
    } catch (_) {
      this.args.onImageSelected(data);
    }
  }

  @action
  didSnap(dataUri) {
    this._showPicture(dataUri);
  }

  @action
  selectPicture(event) {
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.onload = () => {
      let imageData = reader.result;
      this._showPicture(imageData);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  @action
  change() {
    let imageTag = document.getElementsByClassName('image-preview')[0];
    imageTag.classList.add('hidden');
    set(this, 'isWebCam', false);
    this.photoSelected = false;
    this.args.onImageSelected(null);
    if (this.isMobile) {
      // eslint-disable-next-line ember/no-runloop
      scheduleOnce('afterRender', this, this.openFileSelector);
    }
  }

  openFileSelector() {
    let input = document.getElementsByClassName('file-selector')[0];
    input.click();
  }
}
