import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * @param {String}            label
 * @param {Boolean}           isActive
 * @param {Boolean}           isDone
 */
export default class InviteStepsSubstep extends Component {
  @action
  onInsert() {
    if (this.args.isActive) {
      document
        .querySelector('[data-scroll-active-substep-into-view]')
        .scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
}
