import JSONAPIAdapter from '@ember-data/adapter/json-api';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import TryAgainError from 'invite-sign/adapters/try-again-error';

import config from 'invite-sign/config/environment';

export default class V2Adapter extends JSONAPIAdapter {
  @service session;

  namespace = 'api/v2';
  host = config.apiHost;

  @computed('session.sessionData')
  get headers() {
    let headers = { Accept: 'application/vnd.api+json', 'Content-Type': 'application/vnd.api+json' };
    if (this.session.isAuthenticated()) {
      const { access_token } = this.session.sessionData;
      headers['Authorization'] = `Bearer ${access_token}`;
    }
    return headers;
  }

  handleResponse(status) {
    if (status === 401 || status === 404) {
      const error = new TryAgainError();

      error.status = status;

      return error;
    }

    return super.handleResponse(...arguments);
  }
}
