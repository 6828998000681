import V3Adapter from './v3';

export default class EntryAdapter extends V3Adapter {
  buildURL(modelName, id, snapshot, requestType) {
    const url = super.buildURL(...arguments);
    if (requestType === 'findRecord') {
      return url.replace('/v3/', '/v2/');
    }
    if (requestType === 'signOut') {
      const [base] = url.split(`/v3/`);
      return `${base}/v2/entries`;
    }
    return url;
  }
}
