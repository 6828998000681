import Component from '@glimmer/component';

export default class VimeoComponent extends Component {
  get videoUrl() {
    const url = new URL(this.args.url);
    this.setDefaultSearchParams(url.searchParams);
    return url.toString();
  }

  setDefaultSearchParams(params) {
    /* eslint-disable ember/use-ember-get-and-set */
    params.set('loop', 'false');
    params.set('byline', 'false');
    params.set('portrait', 'false');
    params.set('title', 'false');
    params.set('speed', 'true');
    params.set('transparent', '0');
    params.set('gesture', 'media');
  }
}
