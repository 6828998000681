import Model, { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';

export default class UserDocumentTemplateAttachment extends Model {
  @service intl;

  @belongsTo('user-document-template') userDocumentTemplate;
  @belongsTo('user-document-attachment', { async: false }) userDocumentAttachment;

  @attr('boolean') required;
  @attr('number') position;
  @attr('string') description;
  @attr('string') identifier;
  @attr('string') title;

  get localizedTitle() {
    return this.intl.t(`documents.attachments.title:${this.identifier}`) || this.title;
  }

  get localizedDescription() {
    return this.intl.t(`documents.attachments.description:${this.identifier}`) || this.description;
  }
}
