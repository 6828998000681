import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { bool } from 'macro-decorators';

const VISITOR_DOCUMENT_ROUTES = ['visitor.sign-documents', 'visitor.upload-documents'];

export default class VisitorStepsComponent extends Component {
  @service router;
  @service featureFlags;

  @tracked _previousStepOrder = 0;

  /**
   * Default steps
   */
  INITIAL_STEPS = [
    { label: 'steps.full_name', route: 'visitor.index', order: 1 },
    { label: 'steps.type_of_visit', route: 'visitor.visitor-type', order: 2 },
    { label: 'steps.personal_details', route: 'visitor.personal-details', order: 3 },
  ];

  @bool('args.selectedFlow.agreementPage.enabled') hasAgreementStep;
  @bool('args.selectedFlow.userDocumentTemplates.length') hasUserDocumentTemplates;
  @bool('args.selectedFlow.photoPage.enabled') hasPhotoStep;

  get hasDocumentStep() {
    return this.hasUserDocumentTemplates;
  }

  /**
   * @return {Number}
   */
  @computed('_previousStepOrder', 'args.selectedFlow.employeeCentric', 'router.currentRouteName', 'steps.[]')
  get currentStep() {
    const stepRoute = this.stepKeyForRoute(this.router.currentRouteName);
    const step = this.steps.find((step) => step.route === stepRoute);

    if (step) {
      // eslint-disable-next-line ember/no-side-effects
      this._previousStepOrder = step.order;
      return step.order;
    } else if (stepRoute === 'visitor.loading') {
      return this._previousStepOrder;
    } else if (stepRoute === 'visitor.submitted') {
      return this.steps.length + 1;
    } else if (this.args.selectedFlow && get(this.args.selectedFlow, 'employeeCentric')) {
      return 2;
    }

    return this._previousStepOrder;
  }

  /**
   * @return {Number}
   */
  get steps() {
    let totalSteps = [...this.INITIAL_STEPS];

    if (this.args.availableFlows?.length === 1) {
      totalSteps.splice(1, 1);
      set(totalSteps.lastObject, 'order', 2);
    }

    if (!this.args.selectedFlow || get(this.args.selectedFlow, 'employeeCentric')) {
      return totalSteps;
    }

    let order = totalSteps.lastObject.order + 1;

    if (this.hasAgreementStep || this.hasDocumentStep) {
      totalSteps.push({ label: 'steps.documents', route: 'visitor.documents', order });
      order++;
    }

    if (this.hasPhotoStep) {
      totalSteps.push({ label: 'steps.add_photo', route: 'visitor.add-photo', order });
    }

    return totalSteps;
  }

  stepKeyForRoute(route) {
    if (VISITOR_DOCUMENT_ROUTES.includes(route)) {
      return 'visitor.documents';
    }

    return route;
  }
}
