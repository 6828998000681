import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { restartableTask, timeout } from 'ember-concurrency';

import config from 'invite-sign/config/environment';

const RESULTS_COUNT = 5;

/**
 * @param {String}              name
 * @param {Function}            updateName
 * @param {Number}              placeId
 * @param {Function}            updatePlaceId
 */
export default class LocalityAutocomplete extends Component {
  @service ajax;

  get selection() {
    const { placeId, name } = this.args;

    return placeId && name ? { id: placeId, name } : null;
  }

  get types() {
    return (this.args.type ?? '').split(',');
  }

  get placeholderText() {
    if (this.types.includes('address')) return 'enter_your_address';

    return 'choose_a_city';
  }

  #buildPlacesAutocompleteUrl(term, types = []) {
    const typesFilter = types?.map((type) => (type ? `&filter[types][]=${type}` : '')).join('') ?? '';

    return `${config.apiHost}/api/v3/places/autocomplete?filter[search]=${term}${typesFilter}`;
  }

  @action
  didSelect(option) {
    if (option && option.name && option.id) {
      this.args.updateName(option.name);
      this.args.updatePlaceId(option.id);
    }
  }

  autocompleteTask = restartableTask(async (term) => {
    if (!term) return [];

    await timeout(config.autocompleteDebounceMs);

    const url = this.#buildPlacesAutocompleteUrl(term, this.types);

    try {
      const { data } = await this.ajax.request(url);
      const formattedResults = data
        .slice(0, RESULTS_COUNT)
        .map((place) => ({ id: place.id, name: place.attributes.description }));

      return formattedResults;
    } catch (_) {
      return [];
    }
  });
}
