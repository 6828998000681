import config from 'invite-sign/config/environment';
import { Promise as EmberPromise, resolve } from 'rsvp';

// based on https://karl-sjogren.com/2015/08/05/lazy-loading-extra-scripts-in-ember/

const LOADING: Record<string, Promise<unknown>> = {};

function scriptLoader(id: string, src: string, parent = 'head'): Promise<unknown> {
  let promise: Promise<unknown>;

  if (LOADING[id]) {
    promise = LOADING[id]!;
  } else {
    promise = LOADING[id] = new EmberPromise(function (resolve, reject) {
      /* eslint-disable no-extra-boolean-cast */
      if (!!document.getElementById(id)) {
        delete LOADING[id];

        return resolve(true);
      }
      /* eslint-enable no-extra-boolean-cast */

      const script = document.createElement('script');
      script.async = true;
      script.id = id;
      script.src = src;
      script.type = 'text/javascript';
      script.addEventListener('load', function () {
        resolve(true);
      });
      script.addEventListener('error', function () {
        reject(`Failed to load ${id} (${src})`);
      });
      document.getElementsByTagName(parent)[0]?.appendChild(script);
    }, `Loading external script resource ${id} (${src})`);
  }
  return promise;
}

export default scriptLoader;

export function loadAdobeEmbedApi(): Promise<unknown> {
  if (config.environment === 'test' || !config.adobeApiKey) {
    return resolve();
  }

  const id = 'adobe-embed-pdf-sdk';
  const src = 'https://acrobatservices.adobe.com/view-sdk/viewer.js';

  return scriptLoader(id, src);
}
