import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import config from 'invite-sign/config/environment';
import type SessionService from 'invite-sign/services/session';

export default class InAuthenticateRoute extends Route {
  @service declare session: SessionService;
  @service declare router: RouterService;

  async model({ public_token: publicToken }: { public_token: string }): Promise<void> {
    await this.session.authenticate(
      { grant_type: 'public_device_token', public_device_token: publicToken },
      config.guestClientId
    );

    if (this.session.isAuthenticated()) {
      void this.router.replaceWith('visitor', { queryParams: { public_token: publicToken } });
    } else {
      void this.router.replaceWith('in.error');
    }
  }
}
