import Evented from '@ember/object/evented';
import { run } from '@ember/runloop';
import Service from '@ember/service';
import { dasherize } from '@ember/string';
import { tracked } from '@glimmer/tracking';
import { SplitFactory } from '@splitsoftware/splitio';
import config from 'invite-sign/config/environment';
import { Promise as EmberPromise } from 'rsvp';

export default class FeatureFlagsService extends Service.extend(Evented) {
  @tracked sdk: SplitIO.IBrowserClient | undefined;

  trafficType = 'company';

  isEnabled(feature: string, ...rest: SplitIO.Attributes[] | undefined[]): boolean {
    if (!this.sdk) {
      return false;
    }
    return this.sdk.getTreatment(dasherize(feature), ...rest) === 'on';
  }

  unknownProperty(key: string): boolean {
    return this.isEnabled(dasherize(key));
  }

  async createSdk(companyId: string): Promise<unknown> {
    if (!companyId) {
      return null;
    }

    const factory = SplitFactory({
      core: {
        authorizationKey: config.splitioApiKey,
        key: companyId,
        trafficType: this.trafficType,
      },
      storage: {
        type: 'LOCALSTORAGE',
      },
      startup: {
        requestTimeoutBeforeReady: 9.1, // 9100 ms
        retriesOnFailureBeforeReady: 3, // 3 times
        readyTimeout: 9.1, // 9100 ms
      },
      // these features are only picked up for 'offline' split.io clients
      // which is the default in development and test
      features: config.features,
    });

    const prefixedUpdate = `${this.trafficType}_update`;
    const prefixedTimeout = `${this.trafficType}_timeout`;

    this.sdk = factory.client();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return, ember/no-runloop
    this.sdk.on(this.sdk.Event.SDK_READY, () => run<FeatureFlagsService, any>(this, 'trigger', prefixedUpdate));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return, ember/no-runloop
    this.sdk.on(this.sdk.Event.SDK_UPDATE, () => run<FeatureFlagsService, any>(this, 'trigger', prefixedUpdate));
    this.sdk.on(this.sdk.Event.SDK_READY_TIMED_OUT, () =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return, ember/no-runloop
      run<FeatureFlagsService, any>(this, 'trigger', prefixedTimeout)
    );

    return await new EmberPromise((resolve, reject) => {
      this.on(`${this.trafficType}_update`, this, () => resolve());
      this.on(`${this.trafficType}_timeout`, this, reject);
    });
  }

  willDestroy(...args: Parameters<Service['willDestroy']>): void {
    super.willDestroy(...args);
    if (this.sdk) {
      void this.sdk.destroy();
    }
  }

  trigger(name: string, ...args: Parameters<Evented['trigger']>): void {
    super.trigger(name, ...args);
    if (!name.startsWith('company_')) {
      this.trigger(`company_${name}`, ...args);
    }
  }
}
