import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 * @param {Object}            step
 * @param {Number}            index
 * @param {Number}            currentStep
 */
export default class InviteStepsStep extends Component {
  @service router;

  get isActive() {
    const { currentRouteName } = this.router;
    const { step } = this.args;

    return step.route == currentRouteName || step.substeps?.find((substep) => substep.route === currentRouteName);
  }

  get isDone() {
    return this.args.step.order < this.args.currentStep;
  }

  get currentSubstep() {
    const {
      currentRoute: { params: routeParams },
      currentRouteName: routeName,
    } = this.router;
    if (!this.isActive) {
      return -1;
    }

    const substep = this.args.step.substeps.find(({ id, route }) => {
      if (route != routeName) {
        return false;
      }
      return id === (routeParams.agreement_id || routeParams.document_id);
    });
    if (substep) {
      return substep.order;
    }
    return Number.MAX_SAFE_INTEGER;
  }
}
