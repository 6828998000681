import JSONAPISerializer from '@ember-data/serializer/json-api';
import { isPresent } from '@ember/utils';
import nestedCamelizer from 'invite-sign/utils/nested-camelizer';

export default class IpadConfigSerializer extends JSONAPISerializer {
  normalize() {
    let normalized = super.normalize(...arguments);
    let {
      data: { attributes },
    } = normalized;
    if (isPresent(attributes.multipleLanguageOptions)) {
      attributes.multipleLanguageOptions = nestedCamelizer(attributes.multipleLanguageOptions);
    }
    return normalized;
  }
}
