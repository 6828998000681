import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { get } from '@ember/object';

export default class AgreementPage extends Model {
  @belongsTo('flow') flow;
  @hasMany('agreement') agreements;

  @attr('boolean') enabled;

  agreementsWithout(agreementIds) {
    // eslint-disable-next-line ember/no-get
    const agreements = get(this, 'agreements') || [];
    const excludedIds = (agreementIds || []).invoke('toString');

    return agreements.filter((agreement) => {
      return !excludedIds.includes(agreement.id);
    });
  }
}
