import V3Adapter from './v3';

export default class ProtectInviteAdapter extends V3Adapter {
  pathForType() {
    return 'invites';
  }
  buildURL(modelName, id, snapshot, requestType) {
    const url = super.buildURL(...arguments);
    if (requestType === 'signIn') {
      const [base] = url.split('/invites/');
      return `${base}/entries`;
    }
    return url;
  }
}
