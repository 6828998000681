import JSONAPISerializer from '@ember-data/serializer/json-api';

export default class ProtectInviteSerializer extends JSONAPISerializer {
  modelNameFromPayloadKey(payloadKey) {
    if (payloadKey === 'invites') {
      return 'protect-invite';
    }
    return super.modelNameFromPayloadKey(...arguments);
  }

  normalize(typeClass, hash) {
    let { attributes } = hash;
    if (attributes['approval-status'] && attributes['approval-status']['status']) {
      attributes['approval-status'] = attributes['approval-status']['status'];
    }
    return super.normalize(...arguments);
  }

  serialize(snapshot) {
    const json = super.serialize(...arguments);
    json.data.type = 'invites';
    delete json.data.attributes['preregistration-link'];
    delete json.data.attributes['is-presigned'];
    delete json.data.attributes['approval-status'];

    let { attributes } = json.data;
    const flow = snapshot.belongsTo('flow');

    if (flow) {
      attributes['user-data'] = [
        {
          field: 'Purpose of visit',
          value: flow.attributes().name,
        },
      ];
    }

    return json;
  }
}
