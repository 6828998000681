import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { type Locale } from 'date-fns';
import { task } from 'ember-concurrency';
import type IntlService from 'ember-intl/services/intl';
import fetch from 'fetch';
import getLocale, { type AvailableLocales } from 'invite-sign/utils/date-fns-i18n';

const DEFAULT_ENGLISH = 'en';
const SPECIAL_LANGUAGE_CODES: { [key: string]: string } = Object.freeze({
  'zh-cn': 'zh-hans',
  'zh-tw': 'zh-hant',
  'en-fr': 'fr',
});

type AssetMap = {
  assets: { [key: string]: unknown };
};

type Translations = {
  response: Response;
  targetLang: string | undefined;
};

interface I18nCalendar {
  previousMonth: string;
  nextMonth: string;
  months: string[];
  weekdays: string[];
  weekdaysShort: string[];
}

/**
 * HERE BE DRAGONS!!!!
 *
 * Ember international supports fingerprinting of references to filenames that are not
 * interpolated; however, we do need to interpolate - so we load the asset map to find out
 * the finterprinted URL using a key which is the asset path.
 *
 * In the default case, English, we use an asset map with a key, e.g. 'translations/en.json'.
 *
 * However, ember-intl does fingerprint any string that matches known language files.
 * We want to have a default, English. When the build is "production like", the default path
 * will be fingerprinted even when we are trying to use it as a key for the asset map.
 *
 * So we need to interpolate the use of the key, even for a default language.
 *
 * In the case of a param for ?lang=unknown we want to use the default lang, English.
 */
function getTranslationPath(langCode: string, assetMap: AssetMap): string {
  const key = `translations/${langCode}.json`;

  if (assetMap.assets && assetMap.assets[key]) return <string>assetMap.assets[key];

  return key;
}

async function getAssetMap(): Promise<AssetMap | object> {
  try {
    const assetMap = await fetch('/assets/assetMap.json');
    return await (<Promise<AssetMap>>assetMap.json());
  } catch (_error) {
    return {};
  }
}

function getTranslations(targetLangs: (string | undefined)[], assetMap: AssetMap): Promise<Translations | Response> {
  // eslint-disable-next-line @typescript-eslint/no-misused-promises, no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const targetLang = targetLangs.shift();
    const translationPath = getTranslationPath(targetLang!, assetMap);

    let response = await fetch(`/${translationPath}`);
    // eslint-disable-next-line ember/use-ember-get-and-set
    const isCorrectContentType = /application\/json/.test(response.headers.get('content-type')!);

    if (response.ok && isCorrectContentType) return resolve({ response, targetLang });

    if (targetLangs.length > 0) {
      const message = `No translation for ${translationPath}`;
      // eslint-disable-next-line no-console
      console.debug(message);

      try {
        response = (await getTranslations(targetLangs, assetMap)) as Response;
        resolve(response);
      } catch (error) {
        reject(<Error>error);
      }
    } else {
      reject(<Error>(<unknown>response));
    }
  });
}

export default class LocalizationService extends Service {
  @service declare intl: IntlService;

  @tracked showLanguageSelector = false;
  @tracked _languageCode = DEFAULT_ENGLISH;
  @tracked dateFnsLocale: Locale | undefined;

  get languageCode(): string {
    return this._languageCode;
  }

  set languageCode(code: string) {
    this._languageCode = code;
    this.intl.setLocale(code);
  }

  get i18nCalendar(): I18nCalendar {
    const { intl } = this;

    return {
      previousMonth: intl.t('calendar.previous_month'),
      nextMonth: intl.t('calendar.next_month'),
      months: [
        intl.t('calendar.january'),
        intl.t('calendar.february'),
        intl.t('calendar.march'),
        intl.t('calendar.april'),
        intl.t('calendar.may'),
        intl.t('calendar.june'),
        intl.t('calendar.july'),
        intl.t('calendar.august'),
        intl.t('calendar.september'),
        intl.t('calendar.october'),
        intl.t('calendar.november'),
        intl.t('calendar.december'),
      ],
      weekdays: [
        intl.t('calendar.sunday'),
        intl.t('calendar.monday'),
        intl.t('calendar.tuesday'),
        intl.t('calendar.wednesday'),
        intl.t('calendar.thursday'),
        intl.t('calendar.friday'),
        intl.t('calendar.saturday'),
      ],
      weekdaysShort: [
        intl.t('calendar.sun'),
        intl.t('calendar.mon'),
        intl.t('calendar.tue'),
        intl.t('calendar.wed'),
        intl.t('calendar.thu'),
        intl.t('calendar.fri'),
        intl.t('calendar.sat'),
      ],
    };
  }

  loadLocaleTask = task(
    {
      drop: true,
    },
    async (languageCode: AvailableLocales) => {
      this.dateFnsLocale = getLocale(languageCode);

      const languageKey = languageCode.toLowerCase();
      const targetLang = SPECIAL_LANGUAGE_CODES[languageKey] || languageKey;
      const [language] = targetLang.split('-');
      const listOfLanguages = [targetLang, language, DEFAULT_ENGLISH].filter((l) => Boolean(l));
      const cachedLocale = this.intl.locales.find((l) => listOfLanguages.includes(l));

      if (cachedLocale && cachedLocale !== DEFAULT_ENGLISH) {
        this.languageCode = cachedLocale;
        return;
      }

      try {
        const assetMap = await getAssetMap();
        const { response, targetLang } = (await getTranslations(listOfLanguages, assetMap as AssetMap)) as Translations;
        const translations: unknown = await response.json();

        this.intl.addTranslations(targetLang!, translations);
        this.languageCode = targetLang!;
      } catch (e) {
        if (e instanceof Error) {
          console.error(e.message); // eslint-disable-line no-console
        }
      }
    }
  );
}
