import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { isBlank, typeOf } from '@ember/utils';
import { A, isArray } from '@ember/array';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed, get } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { modelValidator } from 'ember-model-validator';
import { LANGUAGE_CODE_ALIASES } from 'invite-sign/helpers/translator';

@modelValidator
class SignInField extends Model {
  @service intl;
  @service localization;

  @belongsTo('invite') invite;
  @belongsTo('entry') entry; // manyToOne this won't be serialized on the entry model
  @belongsTo('signInFieldPage') signInFieldPage;
  @hasMany('sign-in-field') actionableSignInFields;
  @hasMany('sign-in-field-action', { inverse: 'actionableSignInField' }) actionableSignInFieldActions;
  @hasMany('sign-in-field-action') signInFieldActions;

  @attr('string') value;
  @attr('string') type;
  @attr('string') kind;
  /**
   * @type {String}
   */
  @attr('string') identifier;
  @attr('string') label;
  @attr('number') position;
  @attr('boolean', { defaultValue: false }) required;
  @attr() isTextarea;
  @attr() items;
  @attr('array') ipadLocalizedNames;
  @attr('boolean', { defaultValue: false }) isDisabled;
  @attr() customTranslations;

  @equal('identifier', 'name') isName;
  @equal('identifier', 'host') isHost;
  @equal('identifier', 'email') isEmail;
  @equal('identifier', 'locality') isLocality;
  @equal('identifier', 'locality-address') isFullAddress;
  @equal('kind', 'single-selection') isDropdown;
  @equal('type', 'Purpose of Visit') isPurposeOfVisit;

  // eslint-disable-next-line ember/classic-decorator-hooks
  init() {
    super.init(...arguments);
    this.validations = {
      value: {
        custom: {
          validation: (key, value, model) => !(model.required && isBlank(model.value)),
          message: 'this_is_a_required_field',
        },
        ...(this.isEmail
          ? {
              email: {
                if: (key, value, model) => !model.isDisabled,
                allowBlank: !this.required,
                message: 'invalid_email_address',
              },
            }
          : {}),
      },
    };
  }

  /**
   * @return {Boolean}
   */
  @computed('actionableSignInFieldActions.[]')
  get isParent() {
    // eslint-disable-next-line ember/no-get
    return get(this, 'actionableSignInFieldActions.length') === 0;
  }

  /**
   * @return {Boolean}
   */
  @computed('isParent', 'actionableSignInFields.@each.value', 'actionableSignInFieldActions.[]')
  get isVisibleChild() {
    if (!this.isParent) {
      // eslint-disable-next-line ember/no-get
      const field = get(this, 'actionableSignInFields.firstObject') || {};
      const { value } = field;

      if (isBlank(value)) {
        return false;
      }

      // eslint-disable-next-line ember/no-get
      const action = get(this, 'actionableSignInFieldActions.firstObject');

      return this.extractMatchingParentValues(action.dropdownOption).includes(value);
    }

    return false;
  }

  /**
   * @return {Boolean}
   */
  @computed('isParent', 'isVisibleChild')
  get isVisible() {
    return this.isParent || this.isVisibleChild;
  }

  /**
   * @return {Boolean}
   */
  @computed('isHost', 'isEmail', 'isPurposeOfVisit')
  get renderable() {
    return !this.isHost && !this.isEmail && !this.isPurposeOfVisit;
  }

  @computed('ipadLocalizedNames', 'customTranslations.name')
  get localizedNames() {
    const { ipadLocalizedNames, customTranslations } = this;

    if (ipadLocalizedNames?.length) return ipadLocalizedNames;

    const translations = customTranslations?.name ?? {};
    const result = Object.keys(translations).map((key) => {
      return { languageCode: key, displayName: translations[key] };
    });

    return result;
  }

  /**
   * @return {Array<Object>}
   */
  @computed('items.@each.{position,value}', 'localization.languageCode')
  get itemsValues() {
    const {
      items,
      localization: { languageCode },
    } = this;

    if (!items || typeof items[0] !== 'object') {
      return items;
    }

    return items.sortBy('position').map((item) => {
      const { value } = item;
      const customTranslations = item.customTranslations?.value ?? {};
      const aliases = LANGUAGE_CODE_ALIASES[languageCode] || [];
      const codes = [languageCode, ...aliases];
      const code = codes.find((c) => customTranslations[c]);

      return code ? customTranslations[code] : value;
    });
  }

  extractMatchingParentValues(option) {
    const values = A([option.value]);

    if (isArray(option.ipadLocalizedNames)) {
      values.pushObjects(option.ipadLocalizedNames.mapBy('display-name'));
    }

    if (typeOf(option.customTranslations?.value) === 'object') {
      values.pushObjects(Object.values(option.customTranslations.value));
    }

    return values;
  }
}

export default SignInField;
