import { ImpressionsCacheInMemory } from '../inMemory/ImpressionsCacheInMemory';
import { ImpressionCountsCacheInMemory } from '../inMemory/ImpressionCountsCacheInMemory';
import { EventsCacheInMemory } from '../inMemory/EventsCacheInMemory';
import { validatePrefix } from '../KeyBuilder';
import { KeyBuilderCS } from '../KeyBuilderCS';
import { isLocalStorageAvailable } from '../../utils/env/isLocalStorageAvailable';
import { SplitsCacheInLocal } from './SplitsCacheInLocal';
import { MySegmentsCacheInLocal } from './MySegmentsCacheInLocal';
import { MySegmentsCacheInMemory } from '../inMemory/MySegmentsCacheInMemory';
import { SplitsCacheInMemory } from '../inMemory/SplitsCacheInMemory';
import { DEFAULT_CACHE_EXPIRATION_IN_MILLIS } from '../../utils/constants/browser';
import { InMemoryStorageCSFactory } from '../inMemory/InMemoryStorageCS';
import { LOG_PREFIX } from './constants';
import { DEBUG, NONE, STORAGE_LOCALSTORAGE } from '../../utils/constants';
import { shouldRecordTelemetry, TelemetryCacheInMemory } from '../inMemory/TelemetryCacheInMemory';
import { UniqueKeysCacheInMemoryCS } from '../inMemory/UniqueKeysCacheInMemoryCS';
import { getMatching } from '../../utils/key';
/**
 * InLocal storage factory for standalone client-side SplitFactory
 */
export function InLocalStorage(options) {
    if (options === void 0) { options = {}; }
    var prefix = validatePrefix(options.prefix);
    function InLocalStorageCSFactory(params) {
        // Fallback to InMemoryStorage if LocalStorage API is not available
        if (!isLocalStorageAvailable()) {
            params.settings.log.warn(LOG_PREFIX + 'LocalStorage API is unavailable. Falling back to default MEMORY storage');
            return InMemoryStorageCSFactory(params);
        }
        var settings = params.settings, _a = params.settings, log = _a.log, _b = _a.scheduler, impressionsQueueSize = _b.impressionsQueueSize, eventsQueueSize = _b.eventsQueueSize, _c = _a.sync, impressionsMode = _c.impressionsMode, __splitFiltersValidation = _c.__splitFiltersValidation;
        var matchingKey = getMatching(settings.core.key);
        var keys = new KeyBuilderCS(prefix, matchingKey);
        var expirationTimestamp = Date.now() - DEFAULT_CACHE_EXPIRATION_IN_MILLIS;
        var splits = new SplitsCacheInLocal(log, keys, expirationTimestamp, __splitFiltersValidation);
        var segments = new MySegmentsCacheInLocal(log, keys);
        return {
            splits: splits,
            segments: segments,
            impressions: new ImpressionsCacheInMemory(impressionsQueueSize),
            impressionCounts: impressionsMode !== DEBUG ? new ImpressionCountsCacheInMemory() : undefined,
            events: new EventsCacheInMemory(eventsQueueSize),
            telemetry: shouldRecordTelemetry(params) ? new TelemetryCacheInMemory(splits, segments) : undefined,
            uniqueKeys: impressionsMode === NONE ? new UniqueKeysCacheInMemoryCS() : undefined,
            destroy: function () {
                var _a;
                this.splits = new SplitsCacheInMemory();
                this.segments = new MySegmentsCacheInMemory();
                this.impressions.clear();
                this.impressionCounts && this.impressionCounts.clear();
                this.events.clear();
                (_a = this.uniqueKeys) === null || _a === void 0 ? void 0 : _a.clear();
            },
            // When using shared instanciation with MEMORY we reuse everything but segments (they are customer per key).
            shared: function (matchingKey) {
                var childKeysBuilder = new KeyBuilderCS(prefix, matchingKey);
                return {
                    splits: this.splits,
                    segments: new MySegmentsCacheInLocal(log, childKeysBuilder),
                    impressions: this.impressions,
                    impressionCounts: this.impressionCounts,
                    events: this.events,
                    telemetry: this.telemetry,
                    destroy: function () {
                        this.splits = new SplitsCacheInMemory();
                        this.segments = new MySegmentsCacheInMemory();
                    }
                };
            },
        };
    }
    InLocalStorageCSFactory.type = STORAGE_LOCALSTORAGE;
    return InLocalStorageCSFactory;
}
