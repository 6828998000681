import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';

const DOCUMENT_POSITIONS = Object.freeze({
  'covid-19-vaccine-card': 1,
  'covid-19-test-result': 2,
});

export default class UserDocumentTemplate extends Model {
  @service intl;
  @hasMany('user-document-template-attachment') userDocumentTemplateAttachments;
  @belongsTo('visitor-document', { async: false }) visitorDocument;

  @attr('boolean') optional;
  @attr('string') identifier;
  @attr('string') title;
  @attr('string') description;
  @attr('string') illustrationImageUrl;
  @attr('date') createdAt;
  @attr('date') updatedAt;
  @attr('array', { defaultValue: () => [] }) inputFields;

  hasAnyFilesAssigned() {
    return this.userDocumentTemplateAttachments.any((attachment) => attachment.userDocumentAttachment?.file);
  }

  validUserDocumentAttachments() {
    const validAttachments = [];
    this.userDocumentTemplateAttachments.forEach((attachment) => {
      if (attachment.userDocumentAttachment.isValid) {
        validAttachments.push(attachment.userDocumentAttachment);
      }
    });
    return validAttachments;
  }

  get position() {
    return DOCUMENT_POSITIONS[this.identifier] ?? parseInt(this.id, 10);
  }

  // Use translated string if available
  get localizedTitle() {
    return this.intl.t(`documents.title:${this.identifier}`) || this.title;
  }
}
