import Helper from '@ember/component/helper';
import { isBlank } from '@ember/utils';

export const LANGUAGE_CODE_ALIASES = Object.freeze({
  'zh-hans': Object.freeze(['zh-Hans', 'zh-cn', 'zh-CN']),
  'zh-hant': Object.freeze(['zh-Hant', 'zh-tw', 'zh-TW']),
});

export default class Translator extends Helper {
  compute([translations], { languageCode, defaultText }) {
    if (isBlank(translations)) {
      return defaultText;
    }

    const translated = translations.find(({ languageCode: code }) => {
      const aliases = LANGUAGE_CODE_ALIASES[languageCode] || [];
      const codes = [languageCode, ...aliases];

      return codes.includes(code);
    });

    if (translated) {
      return translated.displayName;
    }

    return defaultText;
  }
}
