import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type SessionService from 'invite-sign/services/session';

export default class UnauthorizedRoute extends Route {
  @service declare router: RouterService;
  @service declare session: SessionService;

  beforeModel(): void {
    if (this.session.sharedDeviceToken) {
      void this.router.replaceWith('in.authenticate', this.session.sharedDeviceToken);
    }

    const loader = document.getElementById('initial-loader');

    if (loader) {
      loader.style.display = 'none';
    }
  }
}
