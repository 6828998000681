import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';

import type LocationModel from './location';

export default class LocationsCapacity extends Model {
  @belongsTo('location')
  declare location: AsyncBelongsTo<LocationModel>;

  @attr('string')
  declare capacityDate: string;

  @attr('number')
  declare capacityLimit: number;

  @attr('number')
  declare onSiteCount: number;

  @attr('number')
  declare expectedCount: number;

  @attr('number')
  declare inCount: number;

  @attr('number')
  declare outCount: number;

  @attr('boolean')
  declare capacityReached: boolean;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'locations-capacity': LocationsCapacity;
  }
}
