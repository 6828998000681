import Model, { attr, belongsTo } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';
import { inject as service } from '@ember/service';

export default class ProtectInvite extends Model {
  @service store;

  @belongsTo('location') location;
  @belongsTo('flow') flow;
  @belongsTo('entry') entry;
  @attr('string') email;
  @attr('string') fullName;
  @attr('date') expectedArrivalTime;
  @attr('string') preregistrationLink;
  @attr('string') approvalStatus;
  @attr('boolean') isPresigned;
  @attr('date') guestUpdatedAt;
}

ProtectInvite.prototype.signIn = memberAction({
  path: 'from_invite',
  type: 'post',
  urlType: 'signIn',
  before() {
    return {
      data: {
        type: 'entries',
        attributes: {
          'sign-in-method': 'Employee web registration',
        },
        relationships: {
          invite: {
            data: {
              type: 'invites',
              id: this.id,
            },
          },
        },
      },
    };
  },
  after(response) {
    const serializer = this.store.serializerFor('entry');
    const id = response.data.id;
    const normalized = serializer.normalizeSingleResponse(this.store, 'entry', response, id, 'findRecord');
    this.store.push(normalized);
    return id;
  },
});
