import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type LocalizationService from 'invite-sign/services/localization';
import { LOCALES, type AvailableLocales } from 'invite-sign/utils/date-fns-i18n';

const detectBrowserLanguage = function (): AvailableLocales {
  const languages = [navigator.language, ...navigator.languages];
  const locales = Object.keys(LOCALES);

  for (let i = 0; i < languages.length; i++) {
    const locale = languages[i] ?? '';
    const lang = locale.split('-')[0] ?? '';

    if (locales.includes(locale)) return locale as AvailableLocales;
    if (locales.includes(lang)) return lang as AvailableLocales;
  }

  return 'en' as AvailableLocales;
};

export default class InRoute extends Route {
  @service declare localization: LocalizationService;

  async beforeModel(): Promise<void> {
    const languageCode = detectBrowserLanguage();

    await this.localization.loadLocaleTask.perform(languageCode);
  }
}
