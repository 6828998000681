import showdown from 'showdown';

export function initialize() {
  showdown.extension('summaryPageMessageMarkdown', function () {
    return [
      {
        type: 'html',
        regex: '<h1 id="(.*)">',
        replace: '<h1 class="text-4xl">',
      },
      {
        type: 'html',
        regex: '<h2 id="(.*)">',
        replace: '<h2 class="text-3xl">',
      },
      {
        type: 'html',
        regex: '<h3 id="(.*)">',
        replace: '<h3 class="text-2xl">',
      },
      {
        type: 'html',
        regex: '<h4 id="(.*)">',
        replace: '<h4 class="text-xl">',
      },
      {
        type: 'html',
        regex: '<h5 id="(.*)">',
        replace: '<h5 class="text-lg">',
      },
      {
        type: 'html',
        regex: '<h6 id="(.*)">',
        replace: '<h6 class="text-base">',
      },
    ];
  });
}

export default {
  name: 'register-showdown-extensions',
  initialize,
};
