import Model, { attr, belongsTo } from '@ember-data/model';
import { camelize } from '@ember/string';

export default class NdaSignature extends Model {
  @belongsTo('agreement') agreement;

  @attr() signature;

  toJSON() {
    const {
      data: { attributes },
    } = this.serialize();

    return Object.entries(attributes).reduce((carry, [key, value]) => {
      carry[camelize(key)] = value;

      return carry;
    }, {});
  }
}
