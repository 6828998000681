import { A } from '@ember/array';
import { get, set } from '@ember/object';
import Service, { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { isBefore, addMinutes, subMinutes, isAfter, isDate } from 'date-fns';
import { storageFor } from 'ember-local-storage';
import fetch from 'fetch';
import config from 'invite-sign/config/environment';
import type EntryModel from 'invite-sign/models/entry';
import type FeatureFlagsService from 'invite-sign/services/feature-flags';

type ProtectAuthData = {
  accessToken: string | null;
  refreshToken: string | null;
  expires: number | Date;
};

type ProtectFlowState = {
  [key: string]:
    | {
        purpose: Purpose;
        accessCode: string;
        meta: unknown;
      }
    | undefined;
};

export type VisitorEntry = {
  id: string;
  locationId: string;
  name: string | null;
  finalizedAt: Date | null;
  signedInAt: Date | null;
  createdAt: Date;
};

type StoredVisitorEntry = {
  [key: string]: VisitorEntry | undefined;
};

type SessionData = {
  expire_date: Date | number;
  access_token: string;
  refresh_token: string | null;
};

type ErrorData = {
  message: string;
  company_logo?: string;
  company_name?: string;
  location_name?: string;
};

type SettingsData = {
  token?: string | null;
  setupAt?: Date;
  reset: () => void;
};

type Purpose = 'register' | 'sign-in' | 'sign-out';

export default class SessionService extends Service {
  @service declare featureFlags: FeatureFlagsService;

  @tracked sessionData: SessionData | null = null;
  @tracked errorData: ErrorData | null = null;
  @tracked timezone: string | undefined;

  @storageFor('protect-auth-data')
  declare protectAuthData: ProtectAuthData;

  @storageFor('protect-flow-state')
  declare protectFlowState: ProtectFlowState;

  @storageFor('visitor-entry')
  declare storedVisitorEntry: StoredVisitorEntry;

  @storageFor('settings')
  declare settings: SettingsData;

  isAuthenticated(): boolean {
    if (!this.sessionData || isEmpty(this.sessionData)) {
      return false;
    }
    const expire_date = isDate(this.sessionData['expire_date'])
      ? this.sessionData['expire_date']
      : new Date(this.sessionData['expire_date']);
    return isBefore(new Date(), expire_date);
  }

  async getToken(body: unknown, clientId: string): Promise<void> {
    const base64ClientId = window.btoa(clientId.concat(':'));
    const options: RequestInit = {
      body: JSON.stringify(body),
      headers: {
        authorization: `Basic ${base64ClientId}`,
        'Content-Type': 'application/vnd.api+json',
      },
      method: 'POST',
      credentials: 'include',
    };
    const response = await fetch(`${config.apiHost}/a/auth/v0/token`, options);
    if (response.ok) {
      this.sessionData = <SessionData>await response.json();
      this.sessionData['expire_date'] = addMinutes(
        new Date(),
        (<SessionData & { expires_in: number }>this.sessionData)['expires_in']
      );
    } else {
      const errorData = <{ error: ErrorData }>await response.json();

      if (errorData?.error) this.errorData = errorData.error;
    }
  }

  authenticate(grant: unknown, clientId: string): Promise<void> {
    return this.getToken(grant, clientId);
  }

  /**
   * Exchanges an auth code for an access token,
   * then saves it in local storage.
   */
  async authenticateWithCode(code: string, companyId: string | number): Promise<void> {
    await this.getToken({ grant_type: 'authorization_code', company_id: companyId, code }, config.guestClientId);
    set(this.protectAuthData, 'accessToken', (<SessionData>this.sessionData).access_token);
    set(this.protectAuthData, 'refreshToken', (<SessionData>this.sessionData).refresh_token);
    set(this.protectAuthData, 'expires', (<SessionData>this.sessionData).expire_date);
  }

  /**
   * Loads auth data from local storage,
   * and uses it if available and not expired.
   */
  useExistingAuthIfPossible(): boolean {
    if (!isEmpty(this.sessionData)) {
      return true;
    }
    const accessToken = get(this.protectAuthData, 'accessToken');
    const refreshToken = get(this.protectAuthData, 'refreshToken');
    const expires = isDate(get(this.protectAuthData, 'expires'))
      ? get(this.protectAuthData, 'expires')
      : new Date(get(this.protectAuthData, 'expires'));
    if (!accessToken || isAfter(new Date(), expires)) {
      this.sessionData = null;
      return false;
    }
    this.sessionData = {
      access_token: accessToken,
      refresh_token: refreshToken,
      expire_date: expires,
    };
    return true;
  }

  /**
   * Initiates a Protect flow by sending a magic link and saving the flow data in local storage.
   */
  async beginProtectFlow(
    name: string,
    email: string,
    purpose: Purpose,
    accessCode: string,
    stateId: string,
    meta: string | undefined = undefined
  ): Promise<boolean> {
    set(this.protectFlowState, stateId, { purpose, accessCode, meta });
    const base64ClientId = window.btoa(config.guestClientId.concat(':'));
    const optional: { invite_id?: string | undefined; entry_id?: string | undefined } = {};
    switch (purpose) {
      case 'sign-in':
        optional.invite_id = meta;
        break;
      case 'sign-out':
        optional.entry_id = meta;
        break;
      default:
        break;
    }
    const options: RequestInit = {
      body: JSON.stringify({
        client_id: config.guestClientId,
        response_type: 'magic_link',
        transport: 'email',
        redirect_uri: `${config.invitesHost}/protect/${accessCode}/callback`,
        protect_access_code: accessCode,
        purpose: `protect-web-${purpose}`,
        state: stateId,
        email,
        name,
        ...optional,
      }),
      headers: {
        authorization: `Basic ${base64ClientId}`,
        'Content-Type': 'application/vnd.api+json',
      },
      method: 'POST',
      credentials: 'include',
    };
    const response = await fetch(`${config.apiHost}/a/auth/v0/authorize/passwordless`, options);
    return response.ok;
  }

  /**
   * Continues a Protect flow by retrieving the flow data from local storage.
   */
  continueProtectFlow(stateId: string): ProtectFlowState[string] | undefined {
    return get(this.protectFlowState, stateId);
  }

  /**
   * Ends a Protect flow by removing the flow data from local storage.
   */
  endProtectFlow(stateId: string): void {
    set(this.protectFlowState, stateId, undefined);
  }

  get stalenessBoundary(): Date {
    const isTtlOneWeek = this.featureFlags.isEnabled('signedInEntriesLookBack');

    // 7 days or 1 day in minutes (using minutes avoids Daylight Saving quirks)
    const minutesToBoundary = isTtlOneWeek ? 7 * 1440 : 1440;

    return subMinutes(new Date(), minutesToBoundary);
  }

  get storedEntries(): VisitorEntry[] {
    const storedEntries = Object.values(get(this, 'storedVisitorEntry.content') ?? {});

    return A(storedEntries).compact();
  }

  get isSharedDevice(): boolean {
    return Boolean(this.sharedDeviceToken);
  }

  get sharedDeviceToken(): string | null | undefined {
    return get(this.settings, 'token');
  }

  shareDeviceWithToken(token: string): void {
    set(this.settings, 'setupAt', new Date());
    set(this.settings, 'token', token);
  }

  clearDeviceSettings(): void {
    get(this, 'settings').reset();
  }

  isStoredEntryForLocation(locationId: string): boolean {
    return A(this.storedEntries).any((entry) => entry.locationId === locationId);
  }

  storedEntriesForLocation(locationId: string): VisitorEntry[] {
    return A(this.storedEntries).filterBy('locationId', locationId);
  }

  removeStoredEntry(id: string): void {
    const { storedVisitorEntry } = this;
    const key = `entry-${id}`;

    set(storedVisitorEntry, key, undefined);
  }

  finalizeEntry(entry: EntryModel): void {
    const { fullName = '', id, finalizedAt, signedInAt, createdAt } = entry;
    const key = `entry-${id}`;
    const name = fullName.trim();
    const locationId = `${<string>get(entry, 'location.id') || ''}`;

    set(this.storedVisitorEntry, key, {
      id,
      locationId,
      name,
      finalizedAt,
      signedInAt,
      createdAt,
    });
  }

  cleanupStaleStoredVisitorEntries(): void {
    const { storedVisitorEntry, stalenessBoundary } = this;
    const keys = Object.keys(get(storedVisitorEntry, 'content') ?? {});

    keys.forEach((key: string): void => {
      const storedEntry = get(storedVisitorEntry, key);

      if (!storedEntry?.finalizedAt || isBefore(new Date(storedEntry.finalizedAt), stalenessBoundary)) {
        set(storedVisitorEntry, key, undefined);
      }
    });
  }
}
