import ApplicationSerializer from './application';

export default class InviteSerializer extends ApplicationSerializer {
  attrs = {
    approvalStatus: {
      serialize: false,
    },
    employeeScreeningFlow: {
      serialize: false,
    },
    purposeOfVisitName: {
      serialize: false,
    },
    headerText: {
      serialize: false,
    },
    denyMessage: {
      serialize: false,
    },
    photoEnabled: {
      serialize: false,
    },
    thumbnails: {
      serialize: false,
    },
    qrCodeBase64: {
      serialize: false,
    },
  };
}
