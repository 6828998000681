import { type Locale } from 'date-fns';
import {
  ar,
  bg,
  cs,
  da,
  de,
  enUS,
  es,
  fi,
  fr,
  he,
  hi,
  hr,
  hu,
  it,
  ja,
  ko,
  ms,
  nb,
  nl,
  pl,
  ptBR,
  ro,
  ru,
  sk,
  sl,
  sr,
  sv,
  th,
  uk,
  zhCN,
  zhHK,
} from 'date-fns/locale';

export type AvailableLocales =
  | 'ar'
  | 'bg-bg'
  | 'cs'
  | 'da'
  | 'de'
  | 'en'
  | 'es'
  | 'fi'
  | 'fr-FR'
  | 'fr'
  | 'he'
  | 'hi'
  | 'hr'
  | 'hu'
  | 'it'
  | 'ja'
  | 'ko'
  | 'ms'
  | 'nb'
  | 'nl'
  | 'pl'
  | 'ptBR'
  | 'ro'
  | 'ru'
  | 'sk'
  | 'sl-si'
  | 'sr-rs'
  | 'sv-se'
  | 'th'
  | 'uk'
  | 'zh-hans'
  | 'zh-hant';

export const LOCALES = {
  ar,
  'bg-bg': bg,
  cs,
  da,
  de,
  en: enUS,
  es,
  fi,
  'fr-FR': fr,
  fr,
  he,
  hi,
  hr,
  hu,
  it,
  ja,
  ko,
  ms,
  nb,
  nl,
  pl,
  ptBR,
  ro,
  ru,
  sk,
  'sl-si': sl,
  'sr-rs': sr,
  'sv-se': sv,
  th,
  uk,
  'zh-hans': zhCN,
  'zh-hant': zhHK,
};

export default function (locale: AvailableLocales): Locale {
  return LOCALES[locale];
}
