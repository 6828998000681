import Transform from '@ember-data/serializer/transform';
import { isArray, A } from '@ember/array';

export default class ArrayTransform extends Transform {
  deserialize(value) {
    if (isArray(value)) {
      return A(value);
    } else {
      return A();
    }
  }
  serialize(value) {
    if (isArray(value)) {
      return A(value);
    } else {
      return A();
    }
  }
}
