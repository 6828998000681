import V3Adapter from './v3';
import { inject as service } from '@ember/service';

export default class AgreementAdapter extends V3Adapter {
  @service store;

  /**
   * There be dragons here
   *
   * We only ever have one entry WITH AN ID (the "previous" entry)
   * so we're querying the store directly
   * instead of hopping back up the relationship chain like
   * snapshot.belongsTo('agreement').belongsTo('agreementPage')
   *    .belongsTo('flow').belongsTo('entry').id
   */
  buildURL(modelName, id, snapshot, requestType) {
    if (requestType === 'filledAgreement') {
      const url = this._buildURL(modelName);
      const entries = this.store.peekAll('entry');
      const previousEntry = entries.find((e) => e.id);

      return url + `/${previousEntry.id}/agreements/${snapshot.id}`;
    }

    return super.buildURL(...arguments);
  }

  pathForType() {
    return 'entries';
  }

  handleResponse(status, headers, payload = {}) {
    payload.data.type = 'agreement';
    return super.handleResponse(...arguments);
  }
}
