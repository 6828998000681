import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import LogRocket from 'logrocket';

export default class ApplicationRoute extends Route {
  @service segment;
  @service localization;
  @service router;

  queryParams = {
    lang: {
      refreshModel: true,
    },
  };

  async beforeModel() {
    super.beforeModel(...arguments);

    let { lang } = this.paramsFor('application');

    await this.localization.loadLocaleTask.perform(lang || 'en');
  }

  identifyUser() {
    // Invites path
    const inviteModel = this.modelFor('invite');
    if (inviteModel) {
      const { invite } = inviteModel;
      if (invite && invite.id) {
        this.segment.identifyUser(invite.id, { invite });
        LogRocket.identify(invite.id, { inviteId: invite.id });
      }
    }
  }

  trackPageView() {
    // Invites path
    const inviteModel = this.modelFor('invite');
    if (inviteModel) {
      const { invite } = this.modelFor('invite');

      if (invite && invite.id) {
        let currentRouteName = this.router.currentRouteName ?? 'unknown';
        let path = window.location.pathname.replace(`/${invite.id}`, '');
        // Prevent firing multiple different events when viewing agreements
        path = path.replace(/\/agreement\/[0-9]+/, '/agreement');
        let inviteJson = invite.toJSON();
        delete inviteJson.photo; // Do not send base64 image to segment
        this.segment.trackPageView(currentRouteName, { invite: inviteJson, path });
      }
    }
  }
}
