import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import config from 'invite-sign/config/environment';
import type SessionService from 'invite-sign/services/session';

export default class InSharedRoute extends Route {
  @service declare session: SessionService;
  @service declare router: RouterService;

  async model({ public_token: publicToken }: { public_token: string }): Promise<void> {
    await this.session.authenticate(
      { grant_type: 'public_device_token', public_device_token: publicToken },
      config.guestClientId
    );

    if (!this.session.isAuthenticated()) {
      void this.router.replaceWith('in.error');
    }

    this.session.shareDeviceWithToken(publicToken);
    void this.router.replaceWith('visitor');
  }
}
