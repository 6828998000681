import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { bool, filterBy } from 'macro-decorators';

export default class VisitorStepController extends Controller {
  @service featureFlags;
  @service router;
  @service store;

  @bool('model.entry.flow.agreementPage.enabled') hasAgreementStep;
  @bool('model.entry.incompleteUserDocumentTemplates.length') hasUserDocumentTemplates;
  @bool('model.entry.flow.photoPage.enabled') hasPhotoStep;

  @filterBy('model.entry.agreements', 'enabled', true) availableAgreements;

  get hasDocumentStep() {
    return this.hasUserDocumentTemplates;
  }
}
