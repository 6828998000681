import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { enqueueTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

import config from 'invite-sign/config/environment';
export default class DocumentHandlerUploaderComponent extends Component {
  // ActiveStorage service: https://github.com/algonauti/ember-active-storage
  @service activeStorage;

  @tracked isValid = true;
  @tracked isFile = false;
  @tracked imgSrc = '';

  get directUploadURL() {
    const arg = this.args.directUploadURL;

    return typeof arg === 'string' ? arg : '/a/visitors/api/direct-uploads';
  }

  get prefix() {
    const arg = this.args.prefix;

    return typeof arg === 'string' ? arg : '';
  }

  get validType() {
    return this.args.validType;
  }

  get validSize() {
    return this.args.validSize;
  }

  get onError() {
    return this.args.onError;
  }

  // Normalize initial progress value to "0"
  get progress() {
    return this.uploadTask?.last?.progress || 0;
  }

  // "Reverse" progress bar gets narrower as upload proceeds
  get progressStyle() {
    const remaining = 100 - this.progress;

    return `width: ${remaining}%; transition: width 200ms;`;
  }

  upload(file) {
    const { args, directUploadURL, prefix, activeStorage } = this;
    const { onUpload, onError, onUploadComplete, onProgress } = args;

    // ember-active-storage addon does not support extra params in the POST payload.
    // But API currently supports sending the prefix as a query param.
    const endpoint = prefix ? `${config.apiHost}${directUploadURL}?prefix=${prefix}` : directUploadURL;
    const task = this.uploadTask.perform(file, endpoint, activeStorage, { onUploadComplete, onError, onProgress });

    onUpload?.(task);

    // Ensure task runs in production builds
    return task;
  }

  @action
  _resetState() {
    this.isFile = this.args.isFile ?? false;
    this.imgSrc = typeof this.args.imgSrc === 'string' ? this.args.imgSrc : '';
  }

  @action
  fileSelected(event) {
    this.isValid = false;

    const { files } = event.target;
    const { autoUpload, onFileSelected, onFilePreview } = this.args;

    if (!files?.length) return;

    const file = files.item(0);
    const reader = new FileReader();

    this.isFile = true;

    if (!this.validate(file)) return;

    reader.onload = () => {
      const { result } = reader;
      this.imgSrc = result;

      onFilePreview?.(result);
    };
    reader.readAsDataURL(file);

    onFileSelected?.(file);

    if (autoUpload) {
      this.upload(file);
    }
  }

  @action
  reset() {
    const { onReset, onFileSelected, onFilePreview } = this.args;

    this.imgSrc = '';
    this.isFile = false;
    this.isValid = true;

    onFileSelected?.(null);
    onFilePreview?.(null);
    onReset?.();
  }

  @enqueueTask uploadTask = {
    progress: 0,

    *perform(file, endpoint, activeStorage, callbacks = {}) {
      const { onUploadComplete, onError, onProgress } = callbacks;

      if (!file) {
        throw new Error('Upload halted: no file specified');
      }

      if (!endpoint) {
        throw new Error('Upload halted: no direct upload endpoint specified');
      }

      if (typeof activeStorage.upload !== 'function') {
        throw new Error('Upload halted: invalid Active Storage service specified');
      }

      try {
        const { signedId } = yield activeStorage.upload(file, endpoint, {
          onProgress: (progress) => {
            onProgress?.(progress);
            this.progress = progress;
          },
        });

        onUploadComplete?.(signedId);

        return signedId;
      } catch (error) {
        onError?.(error);
      }
    },
  };

  get validTypeReg() {
    if (this.validType instanceof RegExp) {
      return this.validType;
    }
    return new RegExp(this.validType);
  }

  validate(file) {
    const typeValid = this.validType ? this.validTypeReg.test(file.type) : true;
    const sizeValid = this.validSize ? file.size <= this.validSize : true;

    this.isValid = typeValid && sizeValid;

    return this.isValid;
  }
}
