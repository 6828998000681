import { __extends } from "tslib";
import { AbstractSplitsCacheSync, usesSegments } from '../AbstractSplitsCacheSync';
import { isFiniteNumber } from '../../utils/lang';
/**
 * Default ISplitsCacheSync implementation that stores split definitions in memory.
 * Supported by all JS runtimes.
 */
var SplitsCacheInMemory = /** @class */ (function (_super) {
    __extends(SplitsCacheInMemory, _super);
    function SplitsCacheInMemory() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.splitsCache = {};
        _this.ttCache = {};
        _this.changeNumber = -1;
        _this.splitsWithSegmentsCount = 0;
        return _this;
    }
    SplitsCacheInMemory.prototype.clear = function () {
        this.splitsCache = {};
        this.ttCache = {};
        this.changeNumber = -1;
        this.splitsWithSegmentsCount = 0;
    };
    SplitsCacheInMemory.prototype.addSplit = function (name, split) {
        var previousSplit = this.getSplit(name);
        if (previousSplit) { // We had this Split already
            if (previousSplit.trafficTypeName) {
                var previousTtName = previousSplit.trafficTypeName;
                this.ttCache[previousTtName]--;
                if (!this.ttCache[previousTtName])
                    delete this.ttCache[previousTtName];
            }
            if (usesSegments(previousSplit)) { // Substract from segments count for the previous version of this Split.
                this.splitsWithSegmentsCount--;
            }
        }
        if (split) {
            // Store the Split.
            this.splitsCache[name] = split;
            // Update TT cache
            var ttName = split.trafficTypeName;
            if (ttName) { // safeguard
                if (!this.ttCache[ttName])
                    this.ttCache[ttName] = 0;
                this.ttCache[ttName]++;
            }
            // Add to segments count for the new version of the Split
            if (usesSegments(split))
                this.splitsWithSegmentsCount++;
            return true;
        }
        else {
            return false;
        }
    };
    SplitsCacheInMemory.prototype.removeSplit = function (name) {
        var split = this.getSplit(name);
        if (split) {
            // Delete the Split
            delete this.splitsCache[name];
            var ttName = split.trafficTypeName;
            if (ttName) { // safeguard
                this.ttCache[ttName]--; // Update tt cache
                if (!this.ttCache[ttName])
                    delete this.ttCache[ttName];
            }
            // Update the segments count.
            if (usesSegments(split))
                this.splitsWithSegmentsCount--;
            return true;
        }
        else {
            return false;
        }
    };
    SplitsCacheInMemory.prototype.getSplit = function (name) {
        return this.splitsCache[name] || null;
    };
    SplitsCacheInMemory.prototype.setChangeNumber = function (changeNumber) {
        this.changeNumber = changeNumber;
        return true;
    };
    SplitsCacheInMemory.prototype.getChangeNumber = function () {
        return this.changeNumber;
    };
    SplitsCacheInMemory.prototype.getSplitNames = function () {
        return Object.keys(this.splitsCache);
    };
    SplitsCacheInMemory.prototype.trafficTypeExists = function (trafficType) {
        return isFiniteNumber(this.ttCache[trafficType]) && this.ttCache[trafficType] > 0;
    };
    SplitsCacheInMemory.prototype.usesSegments = function () {
        return this.getChangeNumber() === -1 || this.splitsWithSegmentsCount > 0;
    };
    return SplitsCacheInMemory;
}(AbstractSplitsCacheSync));
export { SplitsCacheInMemory };
