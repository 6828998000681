import Model, { attr, belongsTo } from '@ember-data/model';

export default class UserDocumentTemplateConfiguration extends Model {
  @belongsTo('user-document-template', { async: false }) userDocumentTemplate;

  @attr('boolean') optional;

  get identifier() {
    return this.userDocumentTemplate.identifier;
  }
}
