import Route from '@ember/routing/route';

export default class TryAgainRoute extends Route {
  beforeModel(): void {
    const loader = document.getElementById('initial-loader');
    if (loader) {
      loader.style.display = 'none';
    }
  }
}
