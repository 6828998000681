import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { get, computed } from '@ember/object';
import { memberAction, collectionAction } from 'ember-api-actions';
import { service } from '@ember/service';

export default class Entry extends Model {
  @service session;
  @service store;

  @belongsTo('location', { async: false }) location;
  @belongsTo('flow') flow;
  @belongsTo('invite') invite;
  @hasMany('agreement') agreements;
  @hasMany('approval-check') approvalChecks;
  @hasMany('sign-in-field') signInFields;

  @attr('string') fullName;
  @attr('string') email;
  @attr('date') signedOutAt;
  @attr() photo;
  @attr('string', { defaultValue: 'en' }) selectedLanguage;

  @attr('date') finalizedAt;
  @attr('date') signedInAt;
  @attr('date') createdAt;

  @attr({ defaultValue: () => ({ 'place-id': '' }) }) locality;

  completedUserDocumentTemplates = null;
  validSignedAgreements = null;

  /**
   * @return {String}
   */
  @computed('signInFields.@each.value')
  get hostName() {
    let hostSignInField = this.signInFields.findBy('identifier', 'host');
    return hostSignInField?.value;
  }

  /**
   * @return {Boolean}
   */
  @computed('approvalChecks.@each.status')
  get approved() {
    return this.approvalChecks.every(({ status }) => status === 'approved');
  }

  get incompleteVisitorDocumentConfigurations() {
    // eslint-disable-next-line ember/no-get
    const visitorDocumentTemplateConfigurations = get(this, 'flow.visitorDocumentTemplateConfigurations') || [];
    const completedDocumentIds = (this.completedUserDocumentTemplates || []).invoke('toString');

    return visitorDocumentTemplateConfigurations
      .filter((config) => {
        return !completedDocumentIds.includes(config.userDocumentTemplate.id);
      })
      .sortBy('userDocumentTemplate.position');
  }

  get incompleteUserDocumentTemplates() {
    return this.incompleteVisitorDocumentConfigurations.mapBy('userDocumentTemplate');
  }

  get foundInvite() {
    return this.belongsTo('invite').value();
  }

  isValid() {
    // We don't need to validate the host field or hidden conditional fields
    return this.signInFields
      .map((field) => field.isName || !field.isVisible || field.validate())
      .every((value) => value);
  }
}

// https://github.com/mike-north/ember-api-actions/pull/428
Entry.prototype.finalize = memberAction({
  path: 'finalize',
  type: 'post',
  before() {
    let payload = this.serialize();
    let { attributes } = payload.data;

    if (attributes) {
      delete attributes['photo-base64'];
    }

    return payload;
  },
  after(response) {
    this.store.pushPayload(response);
    this.session.finalizeEntry(this);
  },
});

Entry.prototype.signOut = memberAction({
  path: 'sign-out',
  type: 'post',
  urlType: 'signOut',
  before(locationId) {
    return {
      id: this.id,
      full_name: this.fullName,
      ['location-id']: locationId,
    };
  },
});

Entry.prototype.signOutGuest = memberAction({
  path: 'sign-out',
  type: 'post',
  urlType: 'signOut',
  before({ id, locationId, name, signedInAt, createdAt }) {
    const payload = {
      id,
      full_name: name,
      'location-id': locationId,
      'finalized-at': new Date(),
      'signed-in-at': signedInAt,
      'created-at': createdAt,
    };

    return payload;
  },
});

Entry.prototype.findPrevious = collectionAction({
  path: 'find-previous',
  type: 'GET',
  urlType: 'findRecord',
});
