import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { filterBy } from '@ember/object/computed';

export default class IpadConfig extends Model {
  @belongsTo('location') location;
  @hasMany('flow') flows;

  /**
   * @type {String}
   */
  @attr('string') buttonColor;
  @attr('string', { defaultValue: '#ffffff' }) buttonTextColor;
  @attr('string') companyName;
  /**
   * @type {Number}
   */
  @attr('number') companyId;
  @attr('string') companyLogoUrl;

  /**
   * @type {String}
   */
  @attr('string') defaultLanguageCode;
  @attr('boolean') multipleLanguageEnabled;
  @attr('array') multipleLanguageOptions;
  @attr('boolean') beenHereBeforeEnabled;

  @filterBy('flows', 'enabled') availableFlows;

  /**
   * @return {Boolean}
   */
  @computed('multipleLanguageEnabled', 'multipleLanguageOptions.[]')
  get languagesEnabled() {
    return this.multipleLanguageEnabled && this.multipleLanguageOptions.length > 0;
  }
}
