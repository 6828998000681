import Model, { attr, belongsTo } from '@ember-data/model';
import { service } from '@ember/service';
import {
  addDays,
  endOfDay,
  formatISO,
  isSameDay,
  addMinutes,
  isWithinInterval,
  differenceInMilliseconds,
  formatDistance,
  startOfDay,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export default class ProtectAccessCode extends Model {
  @service intl;
  @service localization;
  @service store;

  @belongsTo('location', { async: false }) location;
  @attr('boolean') allowNonEmployees;
  @attr('string') name;
  @attr('string') companyId;
  @attr('string') logoUrl;
  @attr('string') timezone;
  @attr('string', { defaultValue: '#ff4438' }) buttonColor;
  @attr('string', { defaultValue: '#fff' }) buttonTextColor;
  @attr('number', { defaultValue: -1440 }) registrationEligibilityStartOffset;
  @attr('number', { defaultValue: -1 }) registrationEligibilityEndOffset;

  get todayStart() {
    return startOfDay(utcToZonedTime(new Date(), this.timezone));
  }

  get tomorrowStart() {
    return addDays(this.todayStart, 1);
  }

  get tomorrowEnd() {
    return endOfDay(this.tomorrowStart);
  }

  getEmployeeFlow() {
    return this.store
      .query('flow', {
        filter: {
          employee_centric: true,
          location_id: this.belongsTo('location').id(),
        },
      })
      .then((result) => result.firstObject);
  }

  getProtectInvites(user, flow) {
    return this.store
      .query('protect-invite', {
        filter: {
          email: user.email,
          flow: flow.id,
          location: this.belongsTo('location').id(),
          datetime_from: formatISO(this.todayStart),
          datetime_to: formatISO(this.tomorrowEnd),
        },
        sort: 'created-at',
      })
      .catch(() => []);
  }

  getCapacities() {
    return this.store
      .query('locations-capacity', {
        // get capacities
        filter: {
          location: this.belongsTo('location').id(),
          start_date: formatISO(this.todayStart),
          end_date: formatISO(this.tomorrowEnd),
        },
      })
      .catch(() => []);
  }

  inDailyRegistrationRange(targetDate) {
    const startOffset = this.registrationEligibilityStartOffset;
    const endOffset = this.registrationEligibilityEndOffset;
    const start = addMinutes(targetDate, startOffset);
    const end = addMinutes(targetDate, endOffset);

    return isWithinInterval(utcToZonedTime(new Date(), this.timezone), {
      start,
      end,
    });
  }

  canRegisterIn(targetDate) {
    const startOffset = this.registrationEligibilityStartOffset;
    const start = addMinutes(targetDate, startOffset);
    return differenceInMilliseconds(start, utcToZonedTime(new Date(), this.timezone));
  }

  /**
   * Creates a card to show the user for a given date.
   * "Cards" are a date that a user can register for,
   * or a date + message about why they can't register
   *
   * This method follows similar logic to what's in the Envoy GraphQL server,
   * for generating cards for the mobile app.
   *
   * @typedef {Object} Card
   * @property {ProtectInvite} [invite] - An existing invite, if one exists
   * @property {string|null} forbidden - If set, it's the reason why we can't register
   * @property {Date} arrival - Which date the card is for
   * @property {"Today"|"Tomorrow"} prefix - Will prefix to the label
   *
   * @param {Date|string} date - The date to create a card for
   * @param {[ProtectInvite]} invites - The list of invites for today and tomorrow
   * @param {[LocationsCapacity]} capacities - The list of capacity data for today and tomorrow
   * @returns {Card}
   */
  cardFor(date, invites, capacities) {
    let { intl } = this;

    const arrival = date; // Date here has been already converted
    const prefix = isSameDay(this.todayStart, date) ? intl.t('protect.today') : intl.t('protect.tomorrow');
    const invite = invites.find((invite) => isSameDay(invite.expectedArrivalTime, date));
    const capacity = capacities.find((capacity) => isSameDay(new Date(capacity.capacityDate), date));
    let forbidden = null;

    /**
     * If there's an invite already for that date,
     * if they've already finished the questionnaire then they can't select it,
     * otherwise they should be able to select that date to continue.
     */
    if (invite) {
      forbidden = invite.isPresigned ? intl.t('protect.already_registered') : null;
      return { invite, forbidden, arrival, prefix };
    }

    /**
     * If they're not in the registration range, then they can't select that date.
     */
    if (!this.inDailyRegistrationRange(date)) {
      const registerWhen = this.canRegisterIn(date);
      forbidden =
        registerWhen > 0
          ? intl.t('protect.can_register_in', {
              registerWhen: formatDistance(0, registerWhen, { locale: this.localization.dateFnsLocale }),
            })
          : intl.t('protect.registration_window_passed');
    }

    /**
     * If capacity is reached, then they can't select that date.
     */
    if (capacity && capacity.capacityReached) {
      forbidden = intl.t('protect.capacity_reached');
    }

    return { forbidden, arrival, prefix };
  }

  async getUserCards(user, flow) {
    const invites = await this.getProtectInvites(user, flow);
    const capacities = await this.getCapacities();
    const todaysCard = this.cardFor(this.todayStart, invites, capacities);
    const tomorrowsCard = this.cardFor(this.tomorrowStart, invites, capacities);
    return [todaysCard, tomorrowsCard];
  }
}
