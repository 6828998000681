import Model, { belongsTo, attr } from '@ember-data/model';
import { memberAction } from 'ember-api-actions';
import { inject as service } from '@ember/service';

export default class Agreement extends Model {
  @service store;

  @belongsTo('agreement-page') agreementPage;
  @belongsTo('invite') invite;
  @belongsTo('nda-signature') signature;
  @belongsTo('visitor-nda-signature') visitorSignature;

  @attr('string') body;
  @attr('string') documentUrl;
  @attr('string') footer;
  @attr('string') videoUrl;
  @attr('string') name;
  @attr('boolean') optional;
  @attr('boolean') enabled;
  @attr('number') position;
}

// https://github.com/mike-north/ember-api-actions/pull/428
Agreement.prototype.fillAgreement = memberAction({
  path: 'filled-agreement',
  type: 'get',
  urlType: 'filledAgreement',
  before() {
    return {};
  },
  after(response) {
    this.store.pushPayload(response);
  },
});
