import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { format as _format } from 'date-fns';

export function formatter([date, format, options = {}]) {
  let _date = date ?? new Date();

  if (typeof date === 'string') {
    _date = new Date(_date);
  }

  return _format(_date, format, options);
}

/**
  Return the formatted date string in the given format.
  https://date-fns.org/v2.25.0/docs/format
  @method dateFormat
  @param {Date|Number}            date        the original date
  @param {String}                 format      the string of tokens
  @param {Object}                 options     an object with options
  @return {String} the formatted date string
  @public
*/
export default class DateFormat extends Helper {
  @service localization;

  compute([date, format, options = {}]) {
    const formatOptions = {
      locale: this.localization.dateFnsLocale,
      ...options,
    };
    return formatter([date, format, formatOptions]);
  }
}
