import Model, { attr, hasMany, belongsTo } from '@ember-data/model';
import ObjectProxy from '@ember/object/proxy';
import { cached } from 'tracked-toolbox';
import { get } from '@ember/object';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { format, parse } from 'date-fns';
import { isNone } from '@ember/utils';

export const DATE_FORMAT = 'yyyy-MM-dd';

class InputField extends ObjectProxy {
  get value() {
    return this.visitorDocument.inputFieldsData[this.content.identifier] || this.content.default_value;
  }

  set value(val) {
    this.visitorDocument.inputFieldsData = Object.freeze({
      ...this.visitorDocument.inputFieldsData,
      [this.content.identifier]: val,
    });
  }
}

class DateInputField extends InputField {
  @tracked visitorDocument;

  get value() {
    return super.value ? parse(super.value, DATE_FORMAT, new Date()) : null;
  }

  set value(date) {
    super.value = format(date, DATE_FORMAT);
  }
}
export default class VisitorDocument extends Model {
  @belongsTo('user-document-template', { async: false }) userDocumentTemplate;
  @hasMany('user-document-attachment', { async: false }) userDocumentAttachments;

  @attr('string') entryId;
  @attr('string') inviteId;
  @attr('string') recurringInviteId;
  @attr('string') locationId;
  @attr('boolean') skipped;
  @attr({ defaultValue: () => ({}) }) inputFieldsData;

  @cached
  get inputFields() {
    const inputFields = get(this.userDocumentTemplate, 'inputFields') || A();

    return inputFields.sortBy('position').map((inputField) => {
      if (inputField.type === 'date') {
        return DateInputField.create({ visitorDocument: this, content: inputField });
      }

      return InputField.create({ visitorDocument: this, content: inputField });
    });
  }

  get requiredInputFields() {
    return this.inputFields.filterBy('required');
  }

  get invalidInputFields() {
    return this.requiredInputFields.filter((inputField) => isNone(get(inputField, 'value')));
  }
}
