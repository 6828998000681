import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import config from 'invite-sign/config/environment';
import { isBlank } from '@ember/utils';
import { task, timeout } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import fetch from 'fetch';
import { action } from '@ember/object';

const DEBOUNCE_MS = 250;

export default class HostFieldComponent extends Component {
  @service session;

  @tracked totalResults = 0;
  @tracked _EPSapi;

  @action
  registerPowerSelectAPI(publicAPI) {
    this._EPSapi = publicAPI;
  }

  searchHostTask = task(
    {
      restartable: true,
    },
    async (query) => {
      let employees = [];
      if (isBlank(query) || query.length < 3) {
        this.totalResults = 0;
        return employees;
      }
      // Pause here for DEBOUNCE_MS milliseconds. Because this
      // task is `restartable`, if the user starts typing again,
      // the current search will be canceled at this point and
      // start over from the beginning.
      await timeout(DEBOUNCE_MS);
      const { token_type, access_token } = this.session.sessionData;
      let filter = `filter[query]=${query}`;
      let response = await fetch(`${config.apiHost}/api/v3/host-names?${filter}`, {
        headers: {
          Accept: 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
          Authorization: `${token_type} ${access_token}`,
        },
        method: 'GET',
      });
      if (response.ok) {
        let { data } = await response.json();
        employees = data[0].attributes.employees;
      }
      this.totalResults = employees.length;
      return employees.map(({ name }) => name);
    }
  );
}
