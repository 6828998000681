import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import SignaturePad from 'signature_pad';
import { inject as service } from '@ember/service';
import { localCopy } from 'tracked-toolbox';
import { tracked } from '@glimmer/tracking';

/**
 * @param {String}              signatureDataURL
 * @param {Boolean}             required
 * @param {Boolean}             nextDisabled
 * @param {Boolean}             showRequiredMessage
 * @param {Boolean}             disabled
 * @param {Function}            sendSignatureTask
 * @param {Function}            onClear
 * @param {Function}            back
 * @param {Function}            updateRequiredMessage
 */
export default class SignaturePadComponent extends Component {
  @service router;

  @localCopy('args.nextDisabled', false) nextDisabled;
  @localCopy('args.disabled', false) disabled; // is the signature pad disabled?

  @tracked signaturePad;

  @action
  onInsert() {
    const canvas = document.getElementsByTagName('canvas')[0];
    const { updateRequiredMessage } = this.args;
    const pad = new SignaturePad(canvas);

    pad.addEventListener('beginStroke', () => {
      updateRequiredMessage(false);
    });

    this.recomputeSize(pad, canvas);
    this.signaturePad = pad;
  }

  @action
  onUpdate() {
    if (this.args.signatureDataURL) {
      this.signaturePad.clear();
      this.signaturePad.fromDataURL(this.args.signatureDataURL);
      this.signaturePad.off();
      this.disabled = true;
    } else {
      this.signaturePad.on();
      this.disabled = false;
    }
  }

  recomputeSize(pad, canvas) {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    pad.clear();
  }

  submitTask = task({ drop: true }, async () => {
    let data;
    let shouldSave = true;

    if (this.disabled && this.args.signatureDataURL) {
      data = this.args.signatureDataURL;
      shouldSave = false;
    } else if (this.signaturePad.isEmpty()) {
      data = null;
    } else {
      data = this.signaturePad.toDataURL();
    }

    await this.args.sendSignatureTask.perform(data, shouldSave);
    this.signaturePad.on();
    this.signaturePad.clear();
    this.disabled = false;
  });

  @action
  clear() {
    this.signaturePad.on();
    this.signaturePad.clear();
    this.disabled = false;
    this.args.onClear();
  }
}
