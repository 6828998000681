import V3Adapter from './v3';
import { inject as service } from '@ember/service';

export default class VisitorDocumentAdapter extends V3Adapter {
  @service session;

  get headers() {
    let headers = { Accept: 'application/vnd.api+json', 'Content-Type': 'application/vnd.api+json' };
    if (this.session.isAuthenticated()) {
      const { access_token } = this.session.sessionData;
      headers['Authorization'] = `Bearer ${access_token}`;
    }
    return headers;
  }
}
