import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 * @param {Array}            steps
 */
export default class InviteSteps extends Component {
  @service router;

  get currentStep() {
    const { currentRouteName } = this.router;
    const step = this.args.steps.find((step) => {
      return step.route === currentRouteName || step.substeps?.find((substep) => substep.route === currentRouteName);
    });
    if (step) {
      return step.order;
    }
    return Number.MAX_SAFE_INTEGER;
  }
}
