import EmberRouter from '@embroider/router';
import config from 'invite-sign/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('invite', { path: '/invite/:id' }, function () {
    this.route('show', { path: '' }, function () {
      // eslint-disable-next-line ember/no-shadow-route-definition
      this.route('fill', { path: '' });
      this.route('agreement', { path: 'agreement/:agreement_id' });
      this.route('document', { path: 'document/:document_id' });
      this.route('picture');
      this.route('submitted');
    });
    this.route('already-submitted');
    this.route('status');
  });

  this.route('protect', { path: '/protect/:protect_access_code_id' }, function () {
    this.route('show', { path: '' });
    this.route('callback');
    this.route('register');
    this.route('sign-in');
    this.route('sign-out');
    this.route('skip-employee-screening');
    this.route('submitted');
  });

  this.route('visitor', { path: '/' }, function () {
    this.route('verify-match');
    this.route('visitor-type');
    this.route('personal-details');
    this.route('employee-registration');
    this.route('sign-documents');
    this.route('upload-documents', { path: 'upload-documents/:document_id' });
    this.route('add-photo');
    this.route('submitted');
    this.route('data-opt-out');
    this.route('data-policy');
    this.route('sign-out');
    this.route('sign-out-complete');
  });

  this.route('in', function () {
    this.route('error');
    this.route('authenticate', { path: '/:public_token' });
    this.route('shared', { path: '/:public_token/shared' });
    this.route('disconnect-shared', { path: '/:public_token/disconnect-shared' });
  });

  this.route('signed-out');
  this.route('try-again');
  this.route('unauthorized');
});
