// Special treatments
export var CONTROL = 'control';
export var CONTROL_WITH_CONFIG = {
    treatment: CONTROL,
    config: null
};
// Constants for unknown and not-applicable values
export var UNKNOWN = 'unknown';
export var NA = 'NA';
// Integration data types
export var SPLIT_IMPRESSION = 'IMPRESSION';
export var SPLIT_EVENT = 'EVENT';
// Impression collection modes
export var DEBUG = 'DEBUG';
export var OPTIMIZED = 'OPTIMIZED';
export var NONE = 'NONE';
// SDK Modes
export var LOCALHOST_MODE = 'localhost';
export var STANDALONE_MODE = 'standalone';
export var PRODUCER_MODE = 'producer';
export var CONSUMER_MODE = 'consumer';
export var CONSUMER_PARTIAL_MODE = 'consumer_partial';
// Storage types
export var STORAGE_MEMORY = 'MEMORY';
export var STORAGE_LOCALSTORAGE = 'LOCALSTORAGE';
export var STORAGE_REDIS = 'REDIS';
export var STORAGE_PLUGGABLE = 'PLUGGABLE';
// User consent
export var CONSENT_GRANTED = 'GRANTED'; // The user has granted consent for tracking events and impressions
export var CONSENT_DECLINED = 'DECLINED'; // The user has declined consent for tracking events and impressions
export var CONSENT_UNKNOWN = 'UNKNOWN'; // The user has neither granted nor declined consent for tracking events and impressions
// Telemetry
export var QUEUED = 0;
export var DROPPED = 1;
export var DEDUPED = 2;
export var STANDALONE_ENUM = 0;
export var CONSUMER_ENUM = 1;
export var CONSUMER_PARTIAL_ENUM = 2;
export var OPTIMIZED_ENUM = 0;
export var DEBUG_ENUM = 1;
export var NONE_ENUM = 2;
export var SPLITS = 'sp';
export var IMPRESSIONS = 'im';
export var IMPRESSIONS_COUNT = 'ic';
export var EVENTS = 'ev';
export var TELEMETRY = 'te';
export var TOKEN = 'to';
export var SEGMENT = 'se';
export var MY_SEGMENT = 'ms';
export var TREATMENT = 't';
export var TREATMENTS = 'ts';
export var TREATMENT_WITH_CONFIG = 'tc';
export var TREATMENTS_WITH_CONFIG = 'tcs';
export var TRACK = 'tr';
export var CONNECTION_ESTABLISHED = 0;
export var OCCUPANCY_PRI = 10;
export var OCCUPANCY_SEC = 20;
export var STREAMING_STATUS = 30;
export var SSE_CONNECTION_ERROR = 40;
export var TOKEN_REFRESH = 50;
export var ABLY_ERROR = 60;
export var SYNC_MODE_UPDATE = 70;
export var AUTH_REJECTION = 80;
export var STREAMING = 0;
export var POLLING = 1;
export var REQUESTED = 0;
export var NON_REQUESTED = 1;
export var DISABLED = 0;
export var ENABLED = 1;
export var PAUSED = 2;
