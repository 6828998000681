import { __extends } from "tslib";
import { AbstractSplitsCacheSync, usesSegments } from '../AbstractSplitsCacheSync';
import { isFiniteNumber, toNumber, isNaNNumber } from '../../utils/lang';
import { LOG_PREFIX } from './constants';
/**
 * ISplitsCacheSync implementation that stores split definitions in browser LocalStorage.
 */
var SplitsCacheInLocal = /** @class */ (function (_super) {
    __extends(SplitsCacheInLocal, _super);
    /**
     * @param {KeyBuilderCS} keys
     * @param {number | undefined} expirationTimestamp
     * @param {ISplitFiltersValidation} splitFiltersValidation
     */
    function SplitsCacheInLocal(log, keys, expirationTimestamp, splitFiltersValidation) {
        if (splitFiltersValidation === void 0) { splitFiltersValidation = { queryString: null, groupedFilters: { byName: [], byPrefix: [] }, validFilters: [] }; }
        var _this = _super.call(this) || this;
        _this.log = log;
        _this.cacheReadyButNeedsToFlush = false;
        _this.keys = keys;
        _this.splitFiltersValidation = splitFiltersValidation;
        _this._checkExpiration(expirationTimestamp);
        _this._checkFilterQuery();
        return _this;
    }
    SplitsCacheInLocal.prototype._decrementCount = function (key) {
        var count = toNumber(localStorage.getItem(key)) - 1;
        // @ts-expect-error
        if (count > 0)
            localStorage.setItem(key, count);
        else
            localStorage.removeItem(key);
    };
    SplitsCacheInLocal.prototype._decrementCounts = function (split) {
        try {
            if (split) {
                if (split.trafficTypeName) {
                    var ttKey = this.keys.buildTrafficTypeKey(split.trafficTypeName);
                    this._decrementCount(ttKey);
                }
                if (usesSegments(split)) {
                    var segmentsCountKey = this.keys.buildSplitsWithSegmentCountKey();
                    this._decrementCount(segmentsCountKey);
                }
            }
        }
        catch (e) {
            this.log.error(LOG_PREFIX + e);
        }
    };
    SplitsCacheInLocal.prototype._incrementCounts = function (split) {
        try {
            if (split) {
                if (split.trafficTypeName) {
                    var ttKey = this.keys.buildTrafficTypeKey(split.trafficTypeName);
                    // @ts-expect-error
                    localStorage.setItem(ttKey, toNumber(localStorage.getItem(ttKey)) + 1);
                }
                if (usesSegments(split)) {
                    var segmentsCountKey = this.keys.buildSplitsWithSegmentCountKey();
                    // @ts-expect-error
                    localStorage.setItem(segmentsCountKey, toNumber(localStorage.getItem(segmentsCountKey)) + 1);
                }
            }
        }
        catch (e) {
            this.log.error(LOG_PREFIX + e);
        }
    };
    /**
     * Removes all splits cache related data from localStorage (splits, counters, changeNumber and lastUpdated).
     * We cannot simply call `localStorage.clear()` since that implies removing user items from the storage.
     */
    SplitsCacheInLocal.prototype.clear = function () {
        this.log.info(LOG_PREFIX + 'Flushing Splits data from localStorage');
        // collect item keys
        var len = localStorage.length;
        var accum = [];
        for (var cur = 0; cur < len; cur++) {
            var key = localStorage.key(cur);
            if (key != null && this.keys.isSplitsCacheKey(key))
                accum.push(key);
        }
        // remove items
        accum.forEach(function (key) {
            localStorage.removeItem(key);
        });
        this.hasSync = false;
    };
    SplitsCacheInLocal.prototype.addSplit = function (name, split) {
        try {
            var splitKey = this.keys.buildSplitKey(name);
            var splitFromLocalStorage = localStorage.getItem(splitKey);
            var previousSplit = splitFromLocalStorage ? JSON.parse(splitFromLocalStorage) : null;
            this._decrementCounts(previousSplit);
            localStorage.setItem(splitKey, JSON.stringify(split));
            this._incrementCounts(split);
            return true;
        }
        catch (e) {
            this.log.error(LOG_PREFIX + e);
            return false;
        }
    };
    SplitsCacheInLocal.prototype.removeSplit = function (name) {
        try {
            var split = this.getSplit(name);
            localStorage.removeItem(this.keys.buildSplitKey(name));
            this._decrementCounts(split);
            return true;
        }
        catch (e) {
            this.log.error(LOG_PREFIX + e);
            return false;
        }
    };
    SplitsCacheInLocal.prototype.getSplit = function (name) {
        var item = localStorage.getItem(this.keys.buildSplitKey(name));
        return item && JSON.parse(item);
    };
    SplitsCacheInLocal.prototype.setChangeNumber = function (changeNumber) {
        // when cache is ready but using a new split query, we must clear all split data
        if (this.cacheReadyButNeedsToFlush) {
            this.clear();
            this.cacheReadyButNeedsToFlush = false;
        }
        // when using a new split query, we must update it at the store
        if (this.updateNewFilter) {
            this.log.info(LOG_PREFIX + 'Split filter query was modified. Updating cache.');
            var queryKey = this.keys.buildSplitsFilterQueryKey();
            var queryString = this.splitFiltersValidation.queryString;
            try {
                if (queryString)
                    localStorage.setItem(queryKey, queryString);
                else
                    localStorage.removeItem(queryKey);
            }
            catch (e) {
                this.log.error(LOG_PREFIX + e);
            }
            this.updateNewFilter = false;
        }
        try {
            localStorage.setItem(this.keys.buildSplitsTillKey(), changeNumber + '');
            // update "last updated" timestamp with current time
            localStorage.setItem(this.keys.buildLastUpdatedKey(), Date.now() + '');
            this.hasSync = true;
            return true;
        }
        catch (e) {
            this.log.error(LOG_PREFIX + e);
            return false;
        }
    };
    SplitsCacheInLocal.prototype.getChangeNumber = function () {
        var n = -1;
        var value = localStorage.getItem(this.keys.buildSplitsTillKey());
        if (value !== null) {
            value = parseInt(value, 10);
            return isNaNNumber(value) ? n : value;
        }
        return n;
    };
    SplitsCacheInLocal.prototype.getSplitNames = function () {
        var len = localStorage.length;
        var accum = [];
        var cur = 0;
        while (cur < len) {
            var key = localStorage.key(cur);
            if (key != null && this.keys.isSplitKey(key))
                accum.push(this.keys.extractKey(key));
            cur++;
        }
        return accum;
    };
    SplitsCacheInLocal.prototype.trafficTypeExists = function (trafficType) {
        var ttCount = toNumber(localStorage.getItem(this.keys.buildTrafficTypeKey(trafficType)));
        return isFiniteNumber(ttCount) && ttCount > 0;
    };
    SplitsCacheInLocal.prototype.usesSegments = function () {
        // If cache hasn't been synchronized with the cloud, assume we need them.
        if (!this.hasSync)
            return true;
        var storedCount = localStorage.getItem(this.keys.buildSplitsWithSegmentCountKey());
        var splitsWithSegmentsCount = storedCount === null ? 0 : toNumber(storedCount);
        if (isFiniteNumber(splitsWithSegmentsCount)) {
            return splitsWithSegmentsCount > 0;
        }
        else {
            return true;
        }
    };
    /**
     * Check if the splits information is already stored in browser LocalStorage.
     * In this function we could add more code to check if the data is valid.
     * @override
     */
    SplitsCacheInLocal.prototype.checkCache = function () {
        return this.getChangeNumber() > -1 || this.cacheReadyButNeedsToFlush;
    };
    /**
     * Clean Splits cache if its `lastUpdated` timestamp is older than the given `expirationTimestamp`,
     *
     * @param {number | undefined} expirationTimestamp if the value is not a number, data will not be cleaned
     */
    SplitsCacheInLocal.prototype._checkExpiration = function (expirationTimestamp) {
        var value = localStorage.getItem(this.keys.buildLastUpdatedKey());
        if (value !== null) {
            value = parseInt(value, 10);
            if (!isNaNNumber(value) && expirationTimestamp && value < expirationTimestamp)
                this.clear();
        }
    };
    SplitsCacheInLocal.prototype._checkFilterQuery = function () {
        var _this = this;
        var _a = this.splitFiltersValidation, queryString = _a.queryString, groupedFilters = _a.groupedFilters;
        var queryKey = this.keys.buildSplitsFilterQueryKey();
        var currentQueryString = localStorage.getItem(queryKey);
        if (currentQueryString !== queryString) {
            try {
                // mark cache to update the new query filter on first successful splits fetch
                this.updateNewFilter = true;
                // if cache is ready:
                if (this.checkCache()) {
                    // * set change number to -1, to fetch splits with -1 `since` value.
                    localStorage.setItem(this.keys.buildSplitsTillKey(), '-1');
                    // * remove from cache splits that doesn't match with the new filters
                    this.getSplitNames().forEach(function (splitName) {
                        if (queryString && (
                        // @TODO consider redefining `groupedFilters` to expose a method like `groupedFilters::filter(splitName): boolean`
                        groupedFilters.byName.indexOf(splitName) > -1 ||
                            groupedFilters.byPrefix.some(function (prefix) { return splitName.startsWith(prefix + '__'); }))) {
                            // * set `cacheReadyButNeedsToFlush` so that `checkCache` returns true (the storage is ready to be used) and the data is cleared before updating on first successful splits fetch
                            _this.cacheReadyButNeedsToFlush = true;
                            return;
                        }
                        _this.removeSplit(splitName);
                    });
                }
            }
            catch (e) {
                this.log.error(LOG_PREFIX + e);
            }
        }
        // if the filter didn't change, nothing is done
    };
    return SplitsCacheInLocal;
}(AbstractSplitsCacheSync));
export { SplitsCacheInLocal };
