import JSONAPISerializer from '@ember-data/serializer/json-api';
import { isPresent } from '@ember/utils';

export default class UserDocumentTemplateSerializer extends JSONAPISerializer {
  normalize(typeClass, hash) {
    let { relationships } = hash;
    if (relationships && isPresent(relationships['invite'])) {
      delete relationships['invite'];
    }
    return super.normalize(...arguments);
  }
}
