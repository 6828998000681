import JSONAPISerializer from '@ember-data/serializer/json-api';
import nestedCamelizer from 'invite-sign/utils/nested-camelizer';
import { isPresent } from '@ember/utils';

export default class SignInFieldSerializer extends JSONAPISerializer {
  attrs = {
    customTranslations: {
      serialize: false,
    },
  };

  normalize(typeClass, hash) {
    let { attributes } = hash;
    if (isPresent(attributes['name'])) {
      attributes['label'] = attributes['name'];
      delete attributes['name'];
    }
    if (isPresent(attributes['options'])) {
      attributes['items'] = attributes['options'];
      delete attributes['options'];
    }
    if (isPresent(attributes['items']) && typeof attributes['items'].map === 'function') {
      attributes['items'] = nestedCamelizer(attributes['items']);
    }
    if (isPresent(attributes['ipad-localized-names'])) {
      attributes['ipad-localized-names'] = nestedCamelizer(attributes['ipad-localized-names']);
    }
    return super.normalize(...arguments);
  }
}
