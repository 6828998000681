import { A } from '@ember/array';
import Model, { attr, belongsTo, hasMany, type AsyncBelongsTo, type SyncHasMany } from '@ember-data/model';

import type AgreementPageModel from './agreement-page';
import type IpadConfigModel from './ipad-config';
import type PhotoPageModel from './photo-page';
import type ipadLocalizedName from './sign-in-field';
import type SignInFieldPageModel from './sign-in-field-page';
import type SummaryPageModel from './summary-page';
import type UserDocumentTemplateConfigurationModel from './user-document-template-configuration';

export default class Flow extends Model {
  @belongsTo('ipad-config')
  declare ipadConfig: AsyncBelongsTo<IpadConfigModel>;

  @belongsTo('sign-in-field-page')
  declare signInFieldPage: AsyncBelongsTo<SignInFieldPageModel>;

  @belongsTo('agreement-page')
  declare agreementPage: AsyncBelongsTo<AgreementPageModel>;

  @belongsTo('photo-page')
  declare photoPage: AsyncBelongsTo<PhotoPageModel>;

  @belongsTo('summary-page')
  declare summaryPage: AsyncBelongsTo<SummaryPageModel>;

  @hasMany('user-document-template-configuration', { async: false })
  declare activeUserDocumentTemplateConfigurations: SyncHasMany<UserDocumentTemplateConfigurationModel>;

  @attr('string')
  declare name: string;

  @attr('number')
  declare position: number;

  @attr('boolean')
  declare enabled: boolean;

  @attr('boolean')
  declare employeeCentric: boolean;

  @attr('array')
  declare ipadLocalizedNames: ipadLocalizedName[];

  get visitorDocumentTemplateConfigurations(): SyncHasMany<UserDocumentTemplateConfigurationModel> {
    // @ts-ignore
    if (!(<Flow>this).hasMany('activeUserDocumentTemplateConfigurations').value()) return [];

    return this.activeUserDocumentTemplateConfigurations;
  }

  get userDocumentTemplates(): unknown[] {
    return A(this.visitorDocumentTemplateConfigurations.mapBy('userDocumentTemplate')).sortBy('position');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    flow: Flow;
  }
}
