export function maskEmail(email: string): string {
  // splits an email address into 2 parts (domain & username)
  // domain remains unmasked
  // userName is split, if possible, into smaller pieces
  // each piece is masked based on length
  // then everything is joined back together
  const separators = ['.', '-', '+'];
  const emailParts = email.split('@');
  const userName = emailParts[0];
  const domain = emailParts[1];

  let firstSeparator = '';
  let userNameComponents: string[] | undefined = [];

  for (const separator of separators) {
    userNameComponents = userName?.split(separator);

    if (userNameComponents && userNameComponents.length > 1) {
      firstSeparator = separator;
      break;
    }
  }

  const maskedUserNameComponents = userNameComponents?.map((c) => maskString(c));
  const maskedUserName = maskedUserNameComponents?.join(firstSeparator);

  return [maskedUserName, '@', domain].join('');
}

export function maskPhone(phone: string): string {
  // replaces all numbers with • except the last 4 and the extension
  // changes +1(123)456-7890 into +•(•••)•••-7890
  const separators = ['ext', 'x'];

  let firstSeparator = '';
  let phoneNumberComponents: string[] = [];

  for (const separator of separators) {
    phoneNumberComponents = phone.split(separator);

    if (phoneNumberComponents.length > 1) {
      firstSeparator = separator;
      break;
    }
  }

  const phoneNumber = phoneNumberComponents[0];
  const extension = phoneNumberComponents[1];

  const maskedPhoneNumber = phoneNumber?.replaceAll(/(?!(\d\D*){0,4}$)[0-9]/g, '•');

  return [maskedPhoneNumber, firstSeparator, extension].join('');
}

export function maskValue(value: string): string {
  return value.includes('@') ? maskEmail(value) : maskPhone(value);
}

function maskString(value: string) {
  const characters = [...value];
  const length = characters.length;
  let head = 0;
  let tail = length;

  if (length >= 10) {
    head = 3;
    tail = length - 3;
  } else if (length >= 7) {
    head = 2;
    tail = length - 2;
  } else if (length >= 5) {
    head = 2;
  } else {
    head = 1;
  }

  return characters.fill('•', head, tail).join('');
}
