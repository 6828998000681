var _a, _b, _c;
import { submitterFactory, firstPushWindowDecorator } from './submitter';
import { CONSUMER_MODE, CONSUMER_ENUM, STANDALONE_MODE, CONSUMER_PARTIAL_MODE, STANDALONE_ENUM, CONSUMER_PARTIAL_ENUM, OPTIMIZED, DEBUG, NONE, DEBUG_ENUM, OPTIMIZED_ENUM, NONE_ENUM, CONSENT_GRANTED, CONSENT_DECLINED, CONSENT_UNKNOWN } from '../../utils/constants';
import { SDK_READY, SDK_READY_FROM_CACHE } from '../../readiness/constants';
import { base } from '../../utils/settingsValidation';
import { usedKeysMap } from '../../utils/inputValidation/apiKey';
import { timer } from '../../utils/timeTracker/timer';
import { objectAssign } from '../../utils/lang/objectAssign';
var OPERATION_MODE_MAP = (_a = {},
    _a[STANDALONE_MODE] = STANDALONE_ENUM,
    _a[CONSUMER_MODE] = CONSUMER_ENUM,
    _a[CONSUMER_PARTIAL_MODE] = CONSUMER_PARTIAL_ENUM,
    _a);
var IMPRESSIONS_MODE_MAP = (_b = {},
    _b[OPTIMIZED] = OPTIMIZED_ENUM,
    _b[DEBUG] = DEBUG_ENUM,
    _b[NONE] = NONE_ENUM,
    _b);
var USER_CONSENT_MAP = (_c = {},
    _c[CONSENT_UNKNOWN] = 1,
    _c[CONSENT_GRANTED] = 2,
    _c[CONSENT_DECLINED] = 3,
    _c);
function getActiveFactories() {
    return Object.keys(usedKeysMap).length;
}
function getRedundantActiveFactories() {
    return Object.keys(usedKeysMap).reduce(function (acum, apiKey) {
        return acum + usedKeysMap[apiKey] - 1;
    }, 0);
}
export function getTelemetryConfigStats(mode, storageType) {
    return {
        oM: OPERATION_MODE_MAP[mode],
        st: storageType.toLowerCase(),
        aF: getActiveFactories(),
        rF: getRedundantActiveFactories(),
    };
}
/**
 * Converts data from telemetry cache and settings into /metrics/config request payload.
 */
export function telemetryCacheConfigAdapter(telemetry, settings) {
    return {
        isEmpty: function () { return false; },
        clear: function () { },
        pop: function () {
            var urls = settings.urls, scheduler = settings.scheduler;
            var isClientSide = settings.core.key !== undefined;
            return objectAssign(getTelemetryConfigStats(settings.mode, settings.storage.type), {
                sE: settings.streamingEnabled,
                rR: {
                    sp: scheduler.featuresRefreshRate / 1000,
                    se: isClientSide ? undefined : scheduler.segmentsRefreshRate / 1000,
                    ms: isClientSide ? scheduler.segmentsRefreshRate / 1000 : undefined,
                    im: scheduler.impressionsRefreshRate / 1000,
                    ev: scheduler.eventsPushRate / 1000,
                    te: scheduler.telemetryRefreshRate / 1000,
                },
                uO: {
                    s: urls.sdk !== base.urls.sdk,
                    e: urls.events !== base.urls.events,
                    a: urls.auth !== base.urls.auth,
                    st: urls.streaming !== base.urls.streaming,
                    t: urls.telemetry !== base.urls.telemetry,
                },
                iQ: scheduler.impressionsQueueSize,
                eQ: scheduler.eventsQueueSize,
                iM: IMPRESSIONS_MODE_MAP[settings.sync.impressionsMode],
                iL: settings.impressionListener ? true : false,
                hP: false,
                tR: telemetry.getTimeUntilReady(),
                tC: telemetry.getTimeUntilReadyFromCache(),
                nR: telemetry.getNonReadyUsage(),
                t: telemetry.popTags(),
                i: settings.integrations && settings.integrations.map(function (int) { return int.type; }),
                uC: settings.userConsent ? USER_CONSENT_MAP[settings.userConsent] : 0
            });
        }
    };
}
/**
 * Submitter that periodically posts telemetry data
 */
export function telemetrySubmitterFactory(params) {
    var telemetry = params.storage.telemetry, now = params.platform.now;
    if (!telemetry || !now)
        return; // No submitter created if telemetry cache is not defined
    var settings = params.settings, _a = params.settings, log = _a.log, telemetryRefreshRate = _a.scheduler.telemetryRefreshRate, splitApi = params.splitApi, readiness = params.readiness, sdkReadinessManager = params.sdkReadinessManager;
    var startTime = timer(now);
    var submitter = firstPushWindowDecorator(submitterFactory(log, splitApi.postMetricsUsage, telemetry, telemetryRefreshRate, 'telemetry stats', undefined, 0, true), telemetryRefreshRate);
    readiness.gate.once(SDK_READY_FROM_CACHE, function () {
        telemetry.recordTimeUntilReadyFromCache(startTime());
    });
    sdkReadinessManager.incInternalReadyCbCount();
    readiness.gate.once(SDK_READY, function () {
        telemetry.recordTimeUntilReady(startTime());
        // Post config data when the SDK is ready and if the telemetry submitter was started
        if (submitter.isRunning()) {
            var postMetricsConfigTask = submitterFactory(log, splitApi.postMetricsConfig, telemetryCacheConfigAdapter(telemetry, settings), 0, 'telemetry config', undefined, 0, true);
            postMetricsConfigTask.execute();
        }
    });
    return submitter;
}
