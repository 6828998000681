import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type SessionService from 'invite-sign/services/session';

export default class InIndexRoute extends Route {
  @service declare session: SessionService;
  @service declare router: RouterService;

  // eslint-disable-next-line @typescript-eslint/require-await
  async model(): Promise<void> {
    if (this.session.isAuthenticated()) {
      void this.router.transitionTo('visitor');
    } else {
      void this.router.transitionTo('in.error');
    }
  }
}
