import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import { get, set, setProperties } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import config from 'invite-sign/config/environment';
import type Entry from 'invite-sign/models/entry';
import type { PreviousEntry, EntryAttributes } from 'invite-sign/models/entry';
import type Flow from 'invite-sign/models/flow';
import type Invite from 'invite-sign/models/invite';
import type IpadConfig from 'invite-sign/models/ipad-config';
import type SignInField from 'invite-sign/models/sign-in-field';
import type SessionService from 'invite-sign/services/session';
import type { Header } from 'jsonapi';

const PHONE_ATTR = 'Your Phone Number';

type SearchParams = {
  filter: {
    identifier: string;
    flow?: string;
  };
};

type SearchedInvite = {
  id: Invite['id'];
  attributes: {
    email: string;
    name: string;
    'flow-id': string | number;
    'inviter-name': string;
    'user-data': { field: string; value: string }[];
    'completed-user-document-templates': unknown[];
    'photo-url'?: string;
  };
};

type SearchedReturningVisitor = {
  id: Entry['id'];
  attributes: EntryAttributes;
  validSignedAgreements: unknown[];
  completedUserDocumentTemplates: unknown[];
  relationships: {
    flow: {
      data: {
        type: string;
        id: Flow['id'];
      };
    };
  };
};

export default class VisitorSearchService extends Service {
  @service declare session: SessionService;

  @tracked searchedInvite: SearchedInvite | null = null;
  @tracked searchedReturningVisitor: SearchedReturningVisitor | null = null;

  // Used to track whether or not the user "confirmed" or "rejected" the searched
  // invite.
  @tracked matchConfirmed = true;

  matchedRecord(): SearchedInvite | SearchedReturningVisitor | null {
    return this.searchedInvite || this.searchedReturningVisitor;
  }

  searchInvitesTask = task({ drop: true }, async (identifier) => {
    const headers: Header = { Accept: 'application/vnd.api+json', 'Content-Type': 'application/vnd.api+json' };
    if (this.session.sessionData && this.session.isAuthenticated()) {
      const { access_token } = this.session.sessionData;
      headers['Authorization'] = `Bearer ${access_token}`;
    }

    const searchResp = await fetch(`${config.apiHost}/api/v2/invites/search?identifier=${identifier}`, {
      method: 'GET',
      headers,
    });

    const jsonResp = <{ data: SearchedInvite }>await searchResp.json();
    set(this, 'searchedInvite', jsonResp?.data);

    return jsonResp?.data;
  });

  searchPriorVisitTask = task(async (entry: Entry, identifier: string, flowName: string) => {
    const params: SearchParams = {
      filter: {
        identifier,
      },
    };

    if (flowName) {
      params.filter['flow'] = flowName;
    }

    let searchResp: PreviousEntry | undefined;

    try {
      searchResp = await entry.findPrevious(params);
    } catch (_adapterError) {
      // No result was found
    }

    const searchRespEntry = searchResp?.entry;

    if (!searchRespEntry) return;

    searchRespEntry.validSignedAgreements = searchResp!['valid-signed-agreements'];
    searchRespEntry.completedUserDocumentTemplates = searchResp!['completed-user-document-templates'];

    set(this, 'searchedReturningVisitor', searchRespEntry);

    return searchRespEntry;
  });

  preFillWithPreviousEntryTask = task(async (entry: Entry) => {
    const matchedRecord = this.matchedRecord() as SearchedReturningVisitor;

    const flowId = get(entry, 'flow.id');
    const flowName = <string>get(entry, 'flow.name');

    if (matchedRecord.attributes['flow-id'] != flowId) {
      const returningVisitor = await this.searchPriorVisitTask.perform(
        entry,
        this.identifierForEntry(matchedRecord) as string,
        flowName
      );

      if (!returningVisitor) {
        //reset the searched record
        set(this, 'searchedReturningVisitor', matchedRecord);
      }
    }

    this.setEntryWithReturningVisitor(entry);
  });

  identifierForEntry(entry: SearchedReturningVisitor): string | undefined {
    return isPresent(entry.attributes.email) ? entry.attributes.email : entry.attributes['user-data'][PHONE_ATTR];
  }

  hasRelevantUserData(): boolean {
    const searchedInvite = this.searchedInvite;
    const searchedReturningVisitor = this.searchedReturningVisitor;

    if (!searchedInvite && !searchedReturningVisitor) {
      return false;
    }

    if (searchedInvite?.attributes?.email || searchedReturningVisitor?.attributes?.email) {
      return true;
    }

    if (searchedInvite?.attributes['user-data'].find((userData) => userData.field === PHONE_ATTR)) {
      return true;
    }

    if (searchedReturningVisitor?.attributes['user-data']?.[PHONE_ATTR]) {
      return true;
    }

    return false;
  }

  /**
   * Takes the values returned through searchedInvite, and populates them on the
   * entry.
   */
  setEntryWithInvite(entry: Entry): void {
    const searchedInvite = this.searchedInvite;
    if (!searchedInvite) {
      return;
    }

    void set(
      entry,
      'signInFields',
      // @ts-ignore
      get(entry, 'signInFields').map((signInField: SignInField) => {
        const identifier = get(signInField, 'identifier');
        switch (identifier) {
          case 'email':
            entry.email = searchedInvite.attributes.email;
            setProperties(signInField, {
              value: searchedInvite.attributes.email,
              isDisabled: Boolean(searchedInvite.attributes.email),
            });
            break;
          case 'name':
            set(signInField, 'value', searchedInvite.attributes.name);
            break;
          case 'host':
            setProperties(signInField, {
              value: searchedInvite.attributes['inviter-name'],
              isDisabled: Boolean(searchedInvite.attributes['inviter-name']),
            });
            break;
          default: {
            const userData = searchedInvite.attributes['user-data'].find(
              (datum) => datum.field === get(signInField, 'label')
            );
            if (userData) {
              set(signInField, 'value', userData.value);
            }
          }
        }

        return signInField;
      })
    );

    set(
      entry,
      'completedUserDocumentTemplates',
      A(searchedInvite.attributes['completed-user-document-templates'] as string[])
    );
  }

  /**
   * Takes the values returned through searchedReturningVisitor, and populates them on the
   * entry.
   */
  setEntryWithReturningVisitor(entry: Entry): void {
    const searchedReturningVisitor = this.searchedReturningVisitor;

    void set(
      entry,
      'signInFields',
      // @ts-ignore
      get(entry, 'signInFields').map((signInField: SignInField) => {
        const identifier = get(signInField, 'identifier');

        switch (identifier) {
          case 'email':
            entry.email = searchedReturningVisitor!.attributes.email;
            setProperties(signInField, {
              value: searchedReturningVisitor!.attributes.email,
            });
            break;
          case 'name':
            set(signInField, 'value', searchedReturningVisitor!.attributes.name);
            break;
          case 'host':
            setProperties(signInField, {
              value: searchedReturningVisitor!.attributes.host,
            });
            break;
          default: {
            const label = <string>get(signInField, 'label');
            const userData =
              label in searchedReturningVisitor!.attributes['user-data']
                ? searchedReturningVisitor!.attributes['user-data'][label]
                : null;

            const flowId = get(entry, 'flow.id');

            const isPhoneNumber = label === PHONE_ATTR;
            const matchingFlow = flowId === searchedReturningVisitor!.relationships.flow?.data?.id;
            const preFillable = isPhoneNumber || matchingFlow;

            if (userData && preFillable) {
              set(signInField, 'value', userData);
            }
          }
        }

        return signInField;
      })
    );

    set(entry, 'validSignedAgreements', A(searchedReturningVisitor!.validSignedAgreements as string[]));
    set(
      entry,
      'completedUserDocumentTemplates',
      A(searchedReturningVisitor!.completedUserDocumentTemplates as string[])
    );
  }

  /**
   * Takes the values returned through either searchedInvite or searchedReturningVisitor,
   * and populates them on the entry.
   */
  setEntryWithMatchedRecord(entry: Entry): void {
    if (!this.searchedInvite && !this.searchedReturningVisitor) {
      return;
    }

    this.matchConfirmed = true;

    if (this.searchedInvite) {
      return this.setEntryWithInvite(entry);
    }

    if (this.searchedReturningVisitor) {
      return this.setEntryWithReturningVisitor(entry);
    }
  }

  shouldVerifyMatchForReturningVisitor(ipadConfig: IpadConfig): boolean {
    const { searchedReturningVisitor } = this;
    const matchedFlow = (<NativeArray<Flow>>ipadConfig.availableFlows).find(
      (flow: Flow) => `${flow.id}` === searchedReturningVisitor?.relationships?.flow?.data?.id
    );

    return Boolean(matchedFlow);
  }
}
